import React, { useEffect } from 'react';

import axios from 'axios';
import { ThreeDots } from 'react-loading-icons';
import { useNavigate, useParams } from 'react-router-dom';

import { useDataContext } from 'context/UserContext';

export default function VerifyEmail() {
  const { updateUser } = useDataContext();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios(`/verify_email/${token}`, {
      method: 'POST'
    })
      .then(() => {
        updateUser();
        navigate('/dashboard');
      })
      .catch(() => {
        navigate('/dashboard');
      });
  }, []);

  return (
    <div className="relative flex items-center justify-center h-screen bottom-20">
      <div className="p-5 ">
        <div className="flex flex-col items-center gap-2.5">
          <ThreeDots fill="rgba(0,0,0,0.1)" width="50px" />
          <h1 className="text-3xl font-extrabold tracking-tight">
            Loading application...
          </h1>
          <p className="text-center">
            Thanks for verifying! Please wait while we redirect you to the
            dashboard.
          </p>
        </div>
      </div>
    </div>
  );
}

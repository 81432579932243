/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import AOS from 'aos';
import axios from 'axios';
import clsx from 'clsx';
import toast, { Toaster } from 'react-hot-toast';
import { FaInfoCircle } from 'react-icons/fa';
import { Bars } from 'react-loading-icons';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import NotFound from './landing/pages/404';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import 'aos/dist/aos.css';
import { useDataContext } from './context/UserContext';
import DashNav from './dashboard/components/Nav';
import CustomPlans from './dashboard/pages/CustomPlans';
import Dashboard from './dashboard/pages/Dashboard';
import Plans from './dashboard/pages/Plans';
import Register from './dashboard/pages/Register';
import ResetPassword from './dashboard/pages/ResetPassword';
import Return from './dashboard/pages/Return';
import Settings from './dashboard/pages/Settings';
import SignIn from './dashboard/pages/SignIn';
import SuccessfulPayment from './dashboard/pages/SuccessfulPayment';
import VerifyEmail from './dashboard/pages/VerifyEmail';
import Modal from './global-components/Modal';
import Guide from './guide/Guide';
import OldGuide from './guide/OldGuide';
import Nav from './landing/components/Nav';
import EmailSignup from './landing/pages/EmailSignup';
import Home from './landing/pages/Home';
import PrivacyPolicy from './landing/pages/PrivacyPolicy';
import ToS from './landing/pages/TermsOfService';
import Steps from './steps/Steps';
import Warehouse from './warehouse';

AOS.init({
  once: true
});

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID ?? 'hrjli0vq';

function App() {
  const location = useLocation();

  const { user } = useDataContext();

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && !language.includes('en-US')) {
    language = 'en';
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const [sent, setSent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  window.posthog.featureFlags.override({'auto-2fa': 'auto-2fa-enabled'})



  return (
    <>
      <Modal />
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        {location.pathname.includes('dashboard') && <DashNav />}
        {(location.pathname === '/' ||
          location.pathname.startsWith('/home')) && <Nav />}
        <Routes>
          <Route path="/">
            <Route path="/" index element={<Home />} />
            <Route path="/home" index element={<Home />} />
          </Route>
          <Route path="dashboard">
            <Route
              path="/dashboard"
              index
              element={
                user ? (
                  (user.maxAccounts && user.verifiedEmail) ||
                  user.subscription?.isPaused ? (
                    <Dashboard />
                  ) : !user.verifiedEmail ? (
                    <div className="max-w-screen-sm p-5 mx-auto">
                      <div className="text-neutral-700 w-full rounded-lg border p-2.5">
                        <div className="mb-0.5 flex items-center gap-1.5 font-medium">
                          <FaInfoCircle />
                          {t('register.did')}
                        </div>
                        <p className="text-sm text-neutral-600">
                          {t('register.onAvg')}
                          <span className="text-gradient">
                            {t('register.withinWeek')}
                          </span>{' '}
                          {t('register.afterSignup')}
                        </p>
                      </div>
                      <div className="flex flex-col items-center gap-2.5">
                        <Bars fill="rgba(0,0,0,0.1)" width="50px" />
                        <h1 className="text-3xl font-extrabold tracking-tight">
                          {t('register.checkEmail')}
                        </h1>
                        <p className="text-center">
                          {t('register.clickVerify')}{' '}
                          <span className="font-semibold">{user.username}</span>{' '}
                          {t('register.clickVerify2')}
                        </p>
                        <button
                          className={clsx(
                            'mt-5 rounded-md bg-gray-100 px-5 py-2 text-sm transition hover:bg-gray-200 active:bg-gray-300',
                            sent && 'disabled pointer-events-none'
                          )}
                          type="button"
                          onClick={() => {
                            if (sent === false) {
                              axios.post('/resend_verification').then(() => {
                                toast.success('Please check your email');
                                setSent(true);
                              });
                            }
                          }}
                        >
                          {sent === false
                            ? t('register.resend')
                            : t('register.emailSent')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <Plans />
                  )
                ) : (
                  <Navigate to="/dashboard/signin" />
                )
              }
            />
            <Route
              path="settings"
              element={user ? <Settings /> : <Navigate to="/dashboard" />}
            />
            <Route
              path="register"
              element={user ? <Navigate to="/dashboard" /> : <Register />}
            />
            <Route
              path="signin"
              element={user ? <Navigate to="/dashboard" /> : <SignIn />}
            />
            <Route path="plans" element={<Plans />} />
            <Route path="ugccreators" element={<CustomPlans />} />
          </Route>
          <Route path="reset_password/:token" element={<ResetPassword />} />
          <Route path="verify_email/:token" element={<VerifyEmail />} />
          <Route path="/terms-of-service" element={<ToS />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/email-signup" element={<EmailSignup />} />
          <Route path="/dashboard/warehouse" element={<Warehouse />} />
          <Route path="/payment/success" element={<SuccessfulPayment />} />
          <Route path="/payment/return" element={<Return />} />

          <Route path="steps" element={<Steps />} />
          <Route path="/dashboard/sync" element={window.posthog.getFeatureFlag('auto-2fa') === 'auto-2fa-enabled' ? <Guide /> : <OldGuide />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster />
      </IntercomProvider>
    </>
  );
}

export default App;

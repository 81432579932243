import React, { useState } from 'react';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';

import privacyPolicy from 'assets/privacy-policy.pdf';
import Post from 'landing/components/Post';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PrivacyPolicy() {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: newNumPages }) => {
    setNumPages(newNumPages);
  };

  return (
    <Post
      title="Privacy Policy"
      body={
        <div className="prose">
          <h1>FreebieFlow, LLC Privacy Policy</h1>
          <p>
            Thank you for choosing to be part of our community at FreebieFlow,
            LLC (“Freebie Flow”, “Company”, “we”, “us”, or “our”). We are
            committed to protecting your personal information and your right to
            privacy. If you have any questions or concerns about this privacy
            notice, or our practices with regards to your personal information,
            please contact us at{' '}
            <a href="mailto:support@freebieflow.com">support@freebieflow.com</a>
          </p>
          <p>
            When you use any Company website, mobile site, media form, media
            channel, application, tool, email/text/SMS campaign, event, product,
            service and/or other activity, offering, or publication (regardless
            of how distributed, transmitted, published, or broadcast) provided
            by us related, linked, or otherwise connected thereto, or that links
            to, or references, the User Agreement/Terms of Use or this document,
            including without limitation all content, features and functionality
            thereof, such as widgets, plug-ins, and embeddable players (the
            "Site"), we appreciate that you are trusting us with your personal
            information. We take your privacy very seriously. In this privacy
            notice, we seek to explain to you in the clearest way possible what
            information we collect, how we use it and what rights you have in
            relation to it. We hope you take some time to read through it
            carefully, as it is important. If there are any terms in this
            privacy notice that you do not agree with, please discontinue use of
            our Site immediately.
          </p>
          <p>
            This privacy notice applies to all information collected through our
            Site, as well as any related services, sales, marketing or events.
          </p>
          <p className="font-bold">
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </p>
          <section>
            <h2>1. What Information do we Collect?</h2>
            <p className="font-bold">Personal information you disclose to us</p>
            <p className="italic">
              <span className="font-bold">In Short:</span> We collect
              information that you provide to us.
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the express an interest in obtaining
              information about us or our products and services, when you
              participate in activities on the (such as by posting messages in
              our online forums or entering competitions, contests or giveaways)
              or otherwise when you contact us.
            </p>
            <p>
              The personal information that we collect depends on the context of
              your interactions with us, the choices you make and the products
              and features you use. The personal information we collect may
              include the following:
            </p>
            <p>
              Social Media Login Data. We may provide you with the option to
              register with us using your existing social media account details,
              like your Facebook, Twitter or other social media account. If you
              choose to register in this way, we will collect the information
              described in the section called "HOW DO WE HANDLE YOUR SOCIAL
              LOGINS" below. All personal information that you provide to us
              must be true, complete and accurate, and you must notify us of any
              changes to such personal information.
            </p>
            <p className="font-bold">Information automatically collected</p>
            <p className=" italic">
              <span className="font-bold">In Short:</span> Some information —
              such as your Internet Protocol (IP) address and/or browser and
              device characteristics — is collected automatically when you visit
              the Site.
            </p>
            <p>
              We automatically collect certain information when you visit, use
              or navigate the Site. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about who and when you use our and other technical
              information. This information is primarily needed to maintain the
              security and operation of our Site, and for our internal analytics
              and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
          </section>
          <section>
            <h2>2. Will Your Information be Shared with Anyone?</h2>
            <p>
              <span className="font-bold">In Short:</span> We only share
              information with your consent, to comply with laws, to provide you
              with services, to protect your rights, or to fulfill business
              obligations.
            </p>
            <p>
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <ul>
              <li>
                <span className="font-bold">Consent:</span> We may process your
                data if you have given us specific consent to use your personal
                information in a specific purpose.
              </li>
              <li>
                <span className="font-bold">Legitimate Interests:</span> We may
                process your data when it is reasonably necessary to achieve our
                legitimate business interests.
              </li>
              <li>
                <span className="font-bold">Performance of a Contract:</span>{' '}
                Where we have entered into a contract with you, we may process
                your personal information to fulfill the terms of our contract.
              </li>
              <li>
                <span className="font-bold">Legal Obligations:</span> We may
                disclose your information where we are legally required to do so
                in order to comply with applicable law, governmental requests, a
                judicial proceeding, court order, or legal process, such as in
                response to a court order or a subpoena (including in response
                to public authorities to meet national security or law
                enforcement requirements).
              </li>
              <li>
                <span className="font-bold">Vital Interests:</span> We may
                disclose your information where we believe it is necessary to
                investigate, prevent, or take action regarding potential
                violations of our policies, suspected fraud, situations
                involving potential threats to the safety of any person and
                illegal activities, or as evidence in litigation in which we are
                involved.
              </li>
            </ul>
            <p>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <ul>
              <li>
                <span className="font-bold">Business Transfers:</span> We may
                share or transfer your information in connection with, or during
                negotiations of, any merger, sale of company assets, financing,
                or acquisition of all or a portion of our business to another
                company.
              </li>
              <li>
                <span className="font-bold">Affiliates:</span> We may share your
                information with our affiliates, in which case we will require
                those affiliates to honor this privacy notice. Affiliates
                include our parent company and any subsidiaries, joint venture
                partners or other companies that we control or that are under
                common control with us.
              </li>
              <li>
                <span className="font-bold">Business Partners:</span> We may
                share your information with our business partners to offer you
                certain products, services or promotions.
              </li>
              <li>
                <span className="font-bold">Other Users:</span> When you share
                personal information or otherwise interact with public areas of
                the Site, such personal information may be viewed by all users
                and may be publicly made available outside the in perpetuity. If
                you interact with other users of our and register for our Site
                through a social network (such as Facebook), your contacts on
                the social network will see your name, profile photo, and
                descriptions of your activity. Similarly, other users will be
                able to view descriptions of your activity, communicate with you
                within our Site, and view your profile.
              </li>
            </ul>
          </section>
          <section>
            <h2>3. Do We Use Cookies and Other Tracking Technologies?</h2>
            <p>
              <span className="font-bold">In Short:</span> We may use cookies
              and other tracking technologies to collect and store your
              information.
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </section>
          <section>
            <h2>4. How Do We Handle Your Social Logins?</h2>
            <p>
              <span className="font-bold">In Short:</span> If you choose to
              register or log in to our services using a social media account,
              we may have access to certain information about you.
            </p>
            <p>
              Our offers you the ability to register and login using your
              third-party social media account details (like your Facebook or
              Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile Information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, profile picture as well as
              other information you choose to make public on such social media
              platform.
            </p>
            <p>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the Site. Please note that we do not control, and
              are not responsible for, other uses of your personal information
              by your third-party social media provider. We recommend that you
              review their privacy notice to understand how they collect, use
              and share your personal information, and how you can set your
              privacy preferences on their sites and apps.
            </p>
          </section>
          <section>
            <h2>5. Is your Information Transferred Internationally?</h2>
            <p>
              <span className="font-bold">In Short:</span> We may transfer,
              store, and process your information in countries other than your
              own.
            </p>
            <p>
              Our website is hosted through Cloudflare. To ensure optimal uptime
              and performance, their servers are located across the United
              States and potentially around the world . Please be aware that
              your information may be transferred to, stored, and processed
              across those facilities and by those third parties with whom we
              may share your personal information (see "WILL YOUR INFORMATION BE
              SHARED WITH ANYONE?" above), in the United States and other
              countries.
            </p>
          </section>
          <section>
            <h2>6. How Long do we Keep your Information?</h2>
            <p>
              <span className="font-bold">In Short:</span> We keep your
              information for as long as necessary to fulfill the purposes
              outlined in this privacy notice unless otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than for longer than the period of time in which you have
              an account with us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </section>
          <section>
            <h2>7. Do We Collect Information from Minors?</h2>
            <p>
              <span className="font-bold">In Short:</span> We do not knowingly
              collect data from or market to children under 18 years of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Site, you represent that you are at
              least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Site. If we learn
              that personal information from users less than 18 years of age has
              been collected, we will deactivate the account and take reasonable
              measures to promptly delete such data from our records. If you
              become aware of any data we may have collected from children under
              age 18, please contact us at{' '}
              <a href="mailto:support@freebieflow.com">
                support@freebieflow.com
              </a>
            </p>
          </section>
          <section>
            <h2>8. What are your Privacy Rights?</h2>
            <p>
              <span className="font-bold">In Short:</span> You may review,
              change, or terminate your account at any time.
            </p>
            <h3>Account Information</h3>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </p>
            <h3>Opting out of E-mail Marketing</h3>
            <p>
              You can unsubscribe from our marketing email list at any time by
              clicking on the unsubscribe link in the emails that we send or by
              contacting us using the details provided below. You will then be
              removed from the marketing email list – however, we may still
              communicate with you, for example to send you service-related
              emails that are necessary for the administration and use of your
              account, to respond to service requests, or for other
              non-marketing purposes. To otherwise opt-out, you may contact us
              by emailing{' '}
              <a href="mailto:support@freebieflow.com">
                support@freebieflow.com
              </a>
            </p>
          </section>
          <section>
            <h2>9. Controls for Do-Not-Track Features</h2>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage, no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
          </section>
          <section>
            <h2>10. Do California Residents Have Specific Privacy Rights?</h2>
            <p>
              <span className="font-bold">In Short:</span> Yes, if you are a
              resident of California, you are granted specific rights regarding
              access to your personal information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Site, you have the right to request
              removal of unwanted data that you publicly post on the Site. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Site, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </p>
            <p>
              The California Consumer Privacy Act (CCPA) requires us to disclose
              categories of Personal Information we collect and how we use it,
              the categories of source is from whom we collect Personal
              Information, and the third parties with whom we share it, which we
              have explained above. We are also required to communicate
              information about rights California residents have under
              California law. You may exercise the following rights: Right to
              Know and Access. You may submit a verifiable request for
              information regarding the: (1) categories of Personal Information
              we collect, use, or share; (2) purposes for which categories of
              Personal Information are collected or used by us; (3) categories
              of sources from which we collect Personal Information; and (4)
              specific pieces of Personal Information we have collected about
              you. Right to Equal Service. We will not discriminate against you
              if you exercise your privacy rights. Right to Delete. You may
              submit a verifiable request to close your account and we will
              delete Personal Information about you that we have collected.
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data. If you make a request, we have
              one month to respond to you. If you would like to exercise any of
              these rights, please contact us. We do not sell the Personal
              Information of our users. For more information about these rights,
              please contact us.
            </p>
            <p>
              CalOPPA requires us to disclose categories of Personal Information
              we collect and how we use it, the categories of sources from whom
              we collect Personal Information, and the third parties with whom
              we share it, which we have explained above. CalOPPA users have the
              following rights: Right to Know and Access. You may submit a
              verifiable request for information regarding the: (1) categories
              of Personal Information we collect, use, or share; (2) purposes
              for which categories of Personal Information are collected or used
              by us; (3) categories of sources from which we collect Personal
              Information; and (4) specific pieces of Personal Information we
              have collected about you. Right to Equal Service. We will not
              discriminate against you if you exercise your privacy rights.
              Right to Delete. You may submit a verifiable request to close your
              account and we will delete Personal Information about you that we
              have collected. Right to request that a business that sells a
              consumer's personal data, not sell the consumer's personal data.
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us. We
              do not sell the Personal Information of our users. For more
              information about these rights, please contact us.
            </p>
          </section>
          <section>
            <h2>11. Do We Make Updates to this Notice?</h2>
            <p>
              <span className="font-bold">In Short:</span> Yes, we will update
              this notice as necessary to stay compliant with relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </section>
          <section>
            <h2>12. How Can You Contact Us About this Notice?</h2>
            <p>
              If you have questions or comments about this notice, please email
              us at{' '}
              <a href="mailto:support@freebieflow.com">
                support@freebieflow.com
              </a>
            </p>
          </section>
          <section>
            <h2>
              13. How Can You Review, Update, or Delete the Data We Collect from
              You?
            </h2>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please submit a request form by emailing If
              you have questions or comments about this notice, please email us
              at{' '}
              <a href="mailto:support@freebieflow.com">
                support@freebieflow.com
              </a>
              .
            </p>
            <p>We will respond to your request within 30 days.</p>
          </section>
        </div>
      }
    />
  );
}

export default PrivacyPolicy;

import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';

import { useDataContext } from 'context/UserContext';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
  createPromocode,
  getAffiliate,
  hasAffiliate,
  updatePaypal
} from 'utils/referrals';

const validateEmail = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

function AffiliateProgram() {
  const { user } = useDataContext();

  let language = 'en';

  if (!user.language) {
    const languagedetector = new LanguageDetector();

    language = languagedetector.detect();

    if (language !== 'es' && !language.includes('en-US')) {
      language = 'en';
    }

    user.language = language;
  }

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  const [promocode, setPromocode] = useState('');
  const [hasPromocode, setHasPromocode] = useState(false);

  const [email, setEmail] = useState('');
  const [affiliate, setAffiliate] = useState(null);
  const [makeAffiliate, setMakeAffiliate] = useState(false);

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    hasAffiliate(data => {
      if (data.hasAffiliate) {
        getAffiliate(affiliateData => {
          setAffiliate(affiliateData);
        }, null);
      } else {
        setMakeAffiliate(true);
      }
    });
  }, []);

  const handlePromocodeChange = event => {
    setPromocode(event.target.value.toUpperCase());
  };

  useEffect(() => {
    createPromocode(t, null, data => {
      if (data.hasPromocode) {
        setHasPromocode(true);
        setPromocode(data.promocode);
      }
    });
  }, []);

  const submitCustomPromocode = () => {
    createPromocode(t, promocode).then(res => {
      console.log(res);
    });
  };

  return (
    <>
      {!affiliate && makeAffiliate && (
        <div className="overflow-hidden rounded-md">
          <div className="gradient z-[600] h-1 w-full rounded-t-lg" />
          <div className="mb-5 bg-white p-5 shadow-md">
            <div className="mb-6">
              <div className="text-lg font-medium">
                {t('settings.referralProgram')}
              </div>
              <p className="text-sm">{t('settings.referralProgramInfo')}</p>
            </div>
            <div className="text-lg font-medium">
              {t('settings.paypalEmail')}
            </div>
            <p className="text-sm">{t('settings.paypalInfo')}</p>
            <input
              id="affiliate-paypal"
              type="email"
              className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm md:w-1/3"
              placeholder={t('settings.paypalEmail')}
              value={email}
              onChange={handleEmailChange}
            />
            {email.length > 2 ? (
              <span className="text-xs font-normal text-rose-500">
                {!validateEmail(email) && 'Please enter a valid email address.'}
              </span>
            ) : null}
            <div>
              <button
                className="button-gradient mt-6 rounded-md px-4 py-3 font-medium text-white"
                onClick={() => {
                  if (validateEmail(email)) {
                    updatePaypal(email);
                  } else {
                    toast.error('Please enter a valid email address.');
                  }
                }}
                type="button"
              >
                {t('settings.enroll')}
              </button>
            </div>
          </div>
        </div>
      )}
      {affiliate && (
        <div className="overflow-hidden rounded-md">
          <div className="gradient z-[600] h-1 w-full rounded-t-lg" />
          <div className="mb-10 bg-white p-5 shadow-md">
            <div className="flex gap-8">
              <div>
                <div className="text-lg font-medium">
                  {t('settings.visitorCount')}
                </div>
                <p className="text-sm">{t('settings.peopleVisited')}</p>
                <div className="mt-2 text-2xl font-medium text-gray-500">
                  {affiliate.visitors}
                </div>
              </div>
              <div>
                <div className="text-lg font-medium">
                  {t('settings.purchases')}
                </div>
                <p className="text-sm">{t('settings.peoplePaid')}</p>
                <div className="mt-2 text-2xl font-medium text-gray-500">
                  {affiliate.conversions}
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="text-lg font-medium">
                  {t('settings.customPromocode')}
                </div>
                <p className="text-sm">{t('settings.customDesc')}</p>
                <div className="flex mt-6 items-center">
                  <input
                    id="custom-promocode"
                    type="text"
                    className="mr-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                    placeholder={promocode || t('settings.enterPromo')}
                    // value={promocode}
                    disabled={hasPromocode}
                    onChange={handlePromocodeChange}
                  />
                  <button
                    className={
                      hasPromocode
                        ? 'ml-auto items-center justify-end gap-1.5 rounded-md bg-gray-200 px-4 py-3 font-medium leading-none tracking-wide transition-all lg:inline-flex lg:text-sm'
                        : 'button-gradient rounded-r-md px-4 py-3 font-medium text-white'
                    }
                    disabled={hasPromocode}
                    onClick={submitCustomPromocode}
                  >
                    {t('settings.create')}
                  </button>
                </div>
              </div>
            </div>
            <div className="lg:hidden mt-6">
              <div className="text-lg font-medium">
                {t('settings.customPromocode')}
              </div>
              <p className="text-sm">{t('settings.customDesc')}</p>
              <div className="flex mt-6 items-center">
                <input
                  id="custom-promocode"
                  type="text"
                  className="block w-full px-3 py-2 text-sm placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight"
                  placeholder={promocode || t('settings.enterPromo')}
                  // value={promocode}
                  disabled={hasPromocode}
                  onChange={handlePromocodeChange}
                />
                <button
                  className={
                    hasPromocode
                      ? 'ml-auto items-center justify-end gap-1.5 rounded-md bg-gray-200 px-4 py-3 font-medium leading-none tracking-wide transition-all lg:inline-flex lg:text-sm'
                      : 'button-gradient rounded-r-md px-4 py-3 font-medium text-white'
                  }
                  disabled={hasPromocode}
                  onClick={submitCustomPromocode}
                >
                  {t('settings.create')}
                </button>
              </div>
            </div>
            <div className="mb-6 mt-6">
              <div className="text-lg font-medium">
                {t('settings.referralProgram')}
              </div>
              <p className="text-sm">{t('settings.shareLink')}</p>
            </div>
            <div className="mt-2 flex items-center gap-2.5">
              <input
                id="affiliate-link"
                type="text"
                className="flex-1 rounded-md border border-gray-300 px-4 py-3"
                value={`https://freebieflow.com/?${
                  affiliate.links[0].url.split('?')[1]
                }`}
                readOnly
              />
              <button
                className="button-gradient rounded-md px-4 py-3 font-medium text-white"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://freebieflow.com/?${
                      affiliate.links[0].url.split('?')[1]
                    }`
                  );
                  toast.success(t('settings.copyPopup'));
                }}
                type="button"
              >
                {t('settings.copy')}
              </button>
            </div>
            <div className="mt-6 flex flex-wrap justify-between">
              <div>
                <div className="text-lg font-medium">
                  {t('settings.paypalEmail')}
                </div>
                <p className="text-sm">{t('settings.paypalInfo')}</p>
                <input
                  id="affiliate-paypal"
                  type="email"
                  className="mt-6 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                  placeholder={affiliate.paypal_email || 'PayPal Email'}
                  onChange={handleEmailChange}
                />
                {email.length > 2 ? (
                  <span className="text-xs font-normal text-rose-500">
                    {!validateEmail(email) &&
                      'Please enter a valid email address.'}
                  </span>
                ) : null}
                <div>
                  <button
                    className="button-gradient mt-6 rounded-md px-4 py-3 font-medium text-white"
                    onClick={() => {
                      if (validateEmail(email)) {
                        updatePaypal(email);
                      } else {
                        toast.error('Please enter a valid email address.');
                      }
                    }}
                    type="button"
                  >
                    {t('settings.update')}
                  </button>
                </div>
              </div>
              <div>
                <div className="text-lg font-medium">
                  {t('settings.totalEarnings')}
                </div>
                <p className="text-sm">{t('settings.totalEarningsInfo')}</p>
                <div className="mt-6 text-2xl font-medium text-gray-500">
                  ${affiliate.conversions * 10}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AffiliateProgram;

import React from 'react';

import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import he from 'he';

import { useState } from 'react';

import { formatPrice } from 'utils/analytics';

import { useDataContext } from 'context/UserContext';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

dayjs.extend(relativeTime);

function CheckoutHistory() {
  const { user } = useDataContext();

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && language !== 'en-US') {
    language = 'en';
  }

  if (user && user.language) {
    language = user.language;
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const [{ data: checkoutList = [] }] = useAxios('/analytics/checkouts');

  const [checkoutFilter, setCheckoutFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCheckoutList = checkoutList
    .filter(
      checkout =>
        (typeof checkout.status === 'undefined' &&
          (checkoutFilter.toLowerCase() === '' ||
            checkoutFilter.toLowerCase() === 'pending')) ||
        (typeof checkout.status !== 'undefined' &&
          checkout.status
            .toLowerCase()
            .includes(checkoutFilter.toLowerCase()) &&
          checkout.title.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => new Date(b.time) - new Date(a.time));

  return filteredCheckoutList.length > 0 ? (
    <div className="flex w-full shrink-0 flex-col">
      <div className="mb-2.5 font-semibold">{t('dashboard.recentHits')}</div>
      <div className="mb-4">
        <div className="space-y-4">
          <input
            type="text"
            className={`w-full py-1 rounded-md bg-white border-gray-200 hover:opacity-75 hover:cursor-pointer highlight shadow-sm ${
              searchTerm !== ''
                ? 'focus:outline-none focus:border-blue-300'
                : ''
            }`}
            placeholder={searchTerm === '' ? t('dashboard.search') : searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <select
            className="w-full py-1 rounded-md bg-white border-gray-200 hover:opacity-75 hover:cursor-pointer highlight shadow-sm"
            value={checkoutFilter}
            onChange={e => setCheckoutFilter(e.target.value)}
          >
            <option value="">{t('dashboard.filterValues.0')}</option>
            <option value="arriving">{t('dashboard.filterValues.1')}</option>
            <option value="expected">{t('dashboard.filterValues.2')}</option>
            <option value="pending">{t('dashboard.filterValues.3')}</option>
            <option value="delivered">{t('dashboard.filterValues.4')}</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col gap-2.5">
        {filteredCheckoutList.map((checkout, i) => (
          <div
            key={checkout.id}
            className="highlight flex flex-row gap-5 rounded-md border bg-white p-5 shadow-sm hover:cursor-pointer"
            onClick={() => {
              window.open(`https://www.amazon.com/dp/${checkout.asin}`);
            }}
            type="button"
          >
            <img
              src={checkout.image}
              alt={he.decode(checkout.title)}
              className="h-16 w-16 rounded-md"
            />
            <div>
              <div>{he.decode(checkout.title)}</div>
              <div className="text-xs text-gray-500">{checkout.email}</div>
              <div className="text-xs text-gray-500">
                {dayjs(checkout.time).fromNow(true)} ago
              </div>
              <div className="flex gap-1.5">
                <div className="text-xs text-gray-500 line-through">
                  ${formatPrice(checkout.price)}
                </div>
                <div>
                  <div className="text-xs font-bold text-emerald-500">
                    ${formatPrice(checkout.newPrice)}
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={`text-xs font-bold ${
                    !checkout.status
                      ? 'text-yellow-500'
                      : checkout.status.toLowerCase().includes('delivered')
                      ? 'text-emerald-500'
                      : checkout.status.toLowerCase().includes('pending')
                      ? 'text-yellow-500'
                      : checkout.status.toLowerCase().includes('arriving') ||
                        checkout.status.toLowerCase().includes('expected')
                      ? 'text-gradient'
                      : ''
                  }`}
                >
                  {!checkout.status
                    ? 'Order Status Unknown'
                    : checkout.status.toLowerCase() === 'pending'
                    ? 'Pending delivery'
                    : checkout.status.charAt(0).toUpperCase() +
                      checkout.status.slice(1)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div>
      <div className="mb-2.5 font-semibold">{t('dashboard.recentHits')}</div>
      {checkoutList.length > 0 && (
        <div className="space-y-4">
          <input
            type="text"
            className="w-full py-1 rounded-md bg-white border-gray-200 hover:opacity-75 hover:cursor-pointer highlight shadow-sm"
            placeholder={searchTerm === '' ? t('dashboard.search') : searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <select
            className="w-full py-1 rounded-md bg-white border-gray-200 hover:opacity-75 hover:cursor-pointer highlight shadow-sm"
            value={checkoutFilter}
            onChange={e => setCheckoutFilter(e.target.value)}
          >
            <option value="">{t('dashboard.filterValues.0')}</option>
            <option value="arriving">{t('dashboard.filterValues.1')}</option>
            <option value="expected">{t('dashboard.filterValues.2')}</option>
            <option value="pending">{t('dashboard.filterValues.3')}</option>
            <option value="delivered">{t('dashboard.filterValues.4')}</option>
          </select>
        </div>
      )}
      <div className="mt-2.5 flex items-center justify-center rounded-md border bg-white p-5 text-center text-sm text-gray-400 shadow-sm">
        {t('dashboard.noHits')}
      </div>
    </div>
  );
}

export default CheckoutHistory;

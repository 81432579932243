import React from 'react';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import dayjs from 'dayjs';
import CountUp from 'react-countup';
import { FaChevronRight } from 'react-icons/fa';
import 'react-lazy-load-image-component/src/effects/blur.css';

const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const scrollTo = id => {
  const section = document.getElementById(id);
  const yOffset = -112; // account for fixed navbar
  const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
};

function LatestDeals() {
  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && !language.includes('en-US')) {
    language = 'en';
  }

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div
      id="latest-deals"
      className="wrapper relative mx-auto !max-w-screen-2xl overflow-hidden"
    >
      <div className="container">
        <div className="flex flex-col gap-20 lg:flex-row-reverse">
          <div className="lg:w-1/2">
            <div className="mb-auto grid w-full justify-start gap-10 sm:grid-cols-2">
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing
                    end={84}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator=","
                    prefix="$"
                    suffix="m"
                    duration="5"
                    enableScrollSpy
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">{t('home.worth')}</p>
              </div>
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing
                    end={2}
                    smartEasingAmount={1000000}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator=","
                    suffix="m+"
                    duration="4"
                    enableScrollSpy
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">
                  {t('home.freebiesDelivered')}
                </p>
              </div>
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing
                    end={300}
                    smartEasingAmount={1000000}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator=","
                    suffix="+"
                    prefix="$"
                    duration="4"
                    enableScrollSpy
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">
                  {t('home.savedPerMonth')}
                </p>
              </div>
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing
                    end={100}
                    smartEasingAmount={1000000}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator=","
                    suffix="%"
                    duration="6"
                    enableScrollSpy
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">
                  {t('home.satisfaction')}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            <h2>{t('home.story')}</h2>
            <p className="text-gray-600">{t('home.storyBody')}</p>
            <button
              onClick={() => {
                scrollTo('pricing');
              }}
              className="button-gradient !rounded-full mt-7"
              type="button"
            >
              {t('home.letItflow')} <FaChevronRight className="text-sm" />
            </button>
            <p className="mt-2.5 text-xs leading-5 text-gray-600 w-full">
              {t('home.easyCancel')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestDeals;

import i18n from 'i18n';
import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { LuClipboardCopy, LuLoader2 } from 'react-icons/lu';
import { copyToClipboard } from 'utils/copyToClipboard';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useDataContext } from 'context/UserContext';

const error = props => {
  const { children } = props;
  return <p className="mt-1 text-xs font-medium text-red-500">{children}</p>;
};

const StepWelcome = props => {
  const { language, handleSubmit } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div className="flex flex-col gap-2">
      <p>{t('guide.letsLink')}</p>
      <p>{t('guide.tenMins')}</p>
      <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition mt-12"
        onClick={() => {
          handleSubmit(0);
        }}
      >
        {t('home.getStarted')}!
      </button>
    </div>
  );
};

const StepSignIn = props => {
  const { language, handleSubmit, errorMessage, setErrorMessage } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="flex flex-col gap-4">
      <p>{t('guide.willUse')}</p>
      <p>{t('guide.encrypted')}</p>
      <input
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
        type="text"
        placeholder={t('guide.username')}
        value={email}
        onChange={e => {
          setEmail(e.target.value);
        }}
      />
      <input
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
        type="password"
        placeholder={t('guide.password')}
        value={password}
        onChange={e => {
          setPassword(e.target.value);
        }}
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          if (email === '' || password === '') {
            setErrorMessage(t('guide.pleaseEnter'));
            return;
          }
          handleSubmit(1, { email, password });
        }}
      >
        {t('navBar.signIn')}
      </button>
    </div>
  );
};

const StepSigningIn = props => {
  const { language, setStep } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div className="flex flex-col gap-2">
      <p>{t('guide.signingIn')}</p>
      <p>{t('guide.twoMins')}</p>
      <LuLoader2
        size={'4rem'}
        className="text-gray-300 animate-spin w-full my-8"
      />

      {/* <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          setStep(3);
        }}
      >
        Simulate OTP Request
      </button>
      <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          setStep(4);
        }}
      >
        Simulate 2FA Request
      </button>
      <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          setStep(5);
        }}
      >
        Simulate Authenticator Setup
      </button> */}
    </div>
  );
};

const StepRequestOTP = props => {
  const { language, handleSubmit, errorMessage, context } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  const [otp, setOtp] = useState('');

  return (
    <div className="flex flex-col gap-4">
      {context.message && <p>{context.message}</p>}
      <p>{t('guide.enterCode')}</p>
      <input
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
        type="text"
        placeholder="OTP"
        onChange={event => {
          setOtp(event.target.value);
        }}
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          handleSubmit(4, otp);
        }}
      >
        {t('guide.submit')}
      </button>
    </div>
  );
};

const StepRequest2FA = props => {
  const { language, handleSubmit, errorMessage, context } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  const [code, setCode] = useState('');

  return (
    <div className="flex flex-col gap-4">
      {context.message && <p>{context.message}</p>}
      <p>{t('guide.enterCode')} </p>
      <input
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
        type="text"
        placeholder="2FA Code"
        onChange={event => {
          setCode(event.target.value);
        }}
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          handleSubmit(4, code);
        }}
      >
        {t('guide.submit')}
      </button>
    </div>
  );
};

const StepAuthenticator = props => {
  const { language, handleSubmit, errorMessage, context } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  const [code, setCode] = useState('');
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col sm:flex-row gap-4 justify-between">
        <div className="flex flex-col">
          <ol className="list-decimal leading-loose space-y-1">
            {true ? (
              // desktop w/ qr code
              <>
                <li className="ml-5">
                  Get the <span className="font-bold">FREE</span> "Google
                  Authenticator" app on your phone.
                </li>
                <li className="ml-5 space-y-6">
                  {t('guide.scanQr')}
                  <QRCodeCanvas
                    value={`otpauth://totp/Amazon:${context.email}?secret=${context.twoFactorKey}&issuer=Amazon`}
                  />
                </li>
                <li className="ml-5">Save this key for later!</li>
                <button
                  onClick={() => {
                    copyToClipboard(context.twoFactorKey);
                  }}
                  className="relative w-full bg-gray-100 text-gray-800 py-1 px-3 rounded-md border border-gray-300 hover:brightness-95 transition"
                >
                  <p className="truncate pr-10 text-left">
                    {context.twoFactorKey || 'Loading ...'}
                  </p>
                  <div className="absolute right-2 top-1.5 text-gray-400">
                    <LuClipboardCopy size={'1.5rem'} />
                  </div>
                </button>
              </>
            ) : (
              // all other
              <>
                <li className="ml-5">
                  {t('guide.getThe')}{' '}
                  <span className="font-bold">{t('guide.free')}</span>
                  {t('guide.googleAuth')}
                </li>
                <li className="ml-5">{t('guide.openApp')} </li>
                <li className="ml-5">{t('guide.setup')}</li>
                <li className="ml-5">{t('guide.acctBox')}</li>
                <li className="ml-5">{t('guide.keyBox')}</li>
                <button
                  onClick={() => {
                    copyToClipboard(context.twoFactorKey);
                  }}
                  className="relative w-full bg-gray-100 text-gray-800 py-1 px-3 rounded-md border border-gray-300 hover:brightness-95 transition"
                >
                  <p className="truncate pr-10 text-left">
                    {context.twoFactorKey || 'Loading ...'}
                  </p>
                  <div className="absolute right-2 top-1.5 text-gray-400">
                    <LuClipboardCopy size={'1.5rem'} />
                  </div>
                </button>
                <li className="ml-5">{t('guide.addCode2')}</li>
              </>
            )}
          </ol>
          <div className="space-y-4 mt-8">
            <p>{t('guide.enterCont')}</p>
            <input
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
              type="text"
              placeholder="2FA Code"
              onChange={event => {
                setCode(event.target.value);
              }}
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <button
              className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
              onClick={() => {
                handleSubmit(5, code);
              }}
            >
              {t('signIn.submit')}
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <video
            muted
            controls
            autoPlay
            playsInline
            className={
              'mt-2.5  rounded-md object-cover object-top shadow-lg  h-full aspect-[9/16] w-56 bg-gray-300'
            }
            src={
              language === 'en'
                ? '/videos/desktop/gauth.mp4'
                : '/videos/desktop/gauth-es.mp4'
            }
          />
        </div>
      </div>
    </div>
  );
};

const StepDone = props => {
  const { language, handleSubmit } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div className="flex flex-col gap-4">
      <p>{t('guide.done')}</p>
      <button
        className="gradient rounded-lg w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          handleSubmit(6);
        }}
      >
        {t('guide.goToDash')}
      </button>
    </div>
  );
};

export default function Steps(props) {
  const {
    language,
    step,
    handleSubmit,
    setStep,
    errorMessage,
    setErrorMessage,
    context
  } = props;

  const { updateUser } = useDataContext();

  const { t } = useTranslation('', {
    lng: language
  });

  const steps = [
    {
      id: 'welcome',
      title: t('guide.welcome'),
      content: <StepWelcome language={language} handleSubmit={handleSubmit} />
    },
    {
      id: 'sign-in',
      title: t('guide.signIn'),
      content: (
        <StepSignIn
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )
    },
    {
      id: 'signing-in',
      title: t('guide.signIn'),
      content: <StepSigningIn setStep={setStep} />
    },
    {
      id: 'request-otp',
      title: t('guide.provOtp'),
      content: (
        <StepRequestOTP
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          context={context}
        />
      )
    },
    {
      id: 'request-2fa',
      title: t('guide.prov2fa'),
      content: (
        <StepRequest2FA
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          context={context}
        />
      )
    },
    {
      id: 'authenticator',
      title: t('guide.setAuth'),
      content: (
        <StepAuthenticator
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          context={context}
        />
      )
    },
    {
      id: 'done',
      title: t('guide.done'),
      content: <StepDone handleSubmit={handleSubmit} />
    }
  ];

  return (
    <div className="w-full">
      {/* card */}
      <div className="relative bg-white h-full min-h-96 w-full rounded-xl px-8 py-12 shadow-lg overflow-hidden">
        <div className="absolute left-0 right-0 top-0 gradient h-1" />
        <div className="flex flex-col justify-between gap-4 h-full">
          <div>
            <h1 className="text-4xl font-bold mb-8">{steps[step].title}</h1>
            {steps[step].content}
          </div>
          <div className="flex gap-6 w-min mx-auto mt-8">
            {steps.map((s, i) => {
              return (
                <div
                  onClick={() => {
                    setStep(i);
                  }}
                  className={`w-3 h-3 rounded-full ${
                    s === steps[step] ? 'bg-gray-400' : 'bg-gray-300'
                  }`}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-4 pt-5">
        <img
          className="w-10 h-10 rounded-lg"
          alt={language === 'en' ? 'United States' : 'Spain'}
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
            language === 'en' ? 'US' : 'ES'
          }.svg`}
        />
        <select
          className="rounded-xl border-gray-300"
          name="country"
          id="country"
          defaultValue={language}
          onChange={e => {
            axios('/user', {
              method: 'POST',
              data: {
                language: e.target.value
              }
            })
              .then(() => {
                updateUser();
                toast.success('Language preference successfully updated!');
                i18n.changeLanguage(e.target.value).catch(error => {
                  console.error('Error changing language:', error);
                });
              })
              .catch(e => {
                const error = e?.response?.data?.error;
                toast.error(
                  error ??
                    'Something went wrong while updating language preference!'
                );
              });
          }}
        >
          <option value="en">English</option>
          <option value="es">Español</option>
        </select>
      </div>
    </div>
  );
}

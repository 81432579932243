import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import toast from 'react-hot-toast';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useDataContext } from 'context/UserContext';
import axios from 'axios';

function Card({
  index,
  title,
  description,
  list,
  goForward,
  goBackward,
  first,
  last,
  mobileVideo,
  desktopVideo,
  extra,
  className,
  buttonLabel
}) {
  const { user, updateUser } = useDataContext();

  const [showVideo, setShowVideo] = useState(true);

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && language !== 'en-US') {
    language = 'en';
  }

  language = 'es';

  if (user && user.language) {
    language = user.language;
  }

  const { t, i18n } = useTranslation('', {
    lng: language
  });

  if (buttonLabel === 'Next') {
    buttonLabel = t('guide.next');
  }

  useEffect(() => {
    setShowVideo(true);
  }, [index]);

  return (
    <div
      className={`!text-neutral-800 prose w-full rounded-lg bg-white p-5 shadow-md lg:rounded-3xl lg:p-10 ${className}`}
    >
      <div key={index} transition={{ type: 'tween' }} className="prose">
        <h2 className="mb-2.5">{title}</h2>

        {description ? (
          <div className="flex w-full items-center gap-2.5">{description}</div>
        ) : null}
        {list ? (
          <ol>
            {list.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        ) : null}
        {extra ? (
          <div className="mt-5 flex w-full items-center gap-2.5">{extra}</div>
        ) : null}
        <div
          className={clsx('flex justify-between gap-2.5', last ? null : 'mt-5')}
        >
          {!last && index !== 6 ? (
            <div className="flex w-full justify-between gap-2.5 items-end">
              {index > 0 ? (
                <button
                  onClick={goBackward}
                  className="flex h-[36px] w-[36px] shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
                  type="button"
                  aria-label="Go back"
                >
                  <FaChevronLeft />
                </button>
              ) : (
                <div />
              )}
              {!first && (
                <button
                  onClick={() => {
                    goForward();
                  }}
                  className="flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
                  type="button"
                >
                  {buttonLabel} <FaChevronRight className="text-xs" />
                </button>
              )}
            </div>
          ) : null}

          {first && (
            <button
              onClick={goForward}
              className="gradient flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium !text-white transition hover:-hue-rotate-15 active:-hue-rotate-30 flex-shrink-0"
              type="button"
            >
              {t('guide.getMyKey')} <FaChevronRight className="text-xs" />
            </button>
          )}
        </div>

        {mobileVideo && desktopVideo ? (
          <div>
            {showVideo ? (
              <>
                <h2>{t('guide.videoTutorial')}</h2>
                <video
                  muted
                  controls
                  autoPlay
                  playsInline
                  className="mt-2.5 rounded-md shadow-lg lg:hidden max-h-80 w-full"
                  src={mobileVideo + '#t=0.1'}
                />
                <video
                  muted
                  controls
                  autoPlay
                  playsInline
                  className={clsx(
                    'mt-2.5 hidden rounded-md object-cover object-top shadow-lg lg:block max-h-80 w-full',
                    index !== 3 && 'aspect-square'
                  )}
                  src={desktopVideo}
                />
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="flex justify-center items-center gap-4">
        <img
          className="w-10 h-10 rounded-lg"
          alt={language === 'en' ? 'United States' : 'Spain'}
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
            language === 'en' ? 'US' : 'ES'
          }.svg`}
        />
        <select
          className="rounded-xl border-gray-300"
          name="country"
          id="country"
          defaultValue={language}
          onChange={e => {
            axios('/user', {
              method: 'POST',
              data: {
                language: e.target.value
              }
            })
              .then(() => {
                i18n.changeLanguage(e.target.value).catch(error => {
                  console.error('Error changing language:', error);
                });
                updateUser();
                toast.success('Language preference successfully updated!');
              })
              .catch(e => {
                const error = e?.response?.data?.error;
                toast.error(
                  error ??
                    'Something went wrong while updating language preference!'
                );
              });
          }}
        >
          <option value="en">English</option>
          <option value="es">Español</option>
        </select>
      </div>
    </div>
  );
}

Card.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  goForward: PropTypes.func.isRequired,
  goBackward: PropTypes.func.isRequired,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  mobileVideo: PropTypes.string.isRequired,
  desktopVideo: PropTypes.string.isRequired,
  extra: PropTypes.node,
  className: PropTypes.string,
  buttonLabel: PropTypes.string
};

Card.defaultProps = {
  extra: null,
  className: '',
  buttonLabel: 'Next'
};

export default Card;

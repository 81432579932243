import React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link } from 'react-router-dom';

import { useDataContext } from 'context/UserContext';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

dayjs.extend(relativeTime);

// To use this component: <UglyBanner />

function UglyBanner() {
  const { user } = useDataContext();

  let language = 'en';

  if (user) {
    if (!user.language) {
      const languagedetector = new LanguageDetector();
      language = languagedetector.detect();

      if (language !== 'es' && !language.includes('en-US')) {
        language = 'en';
      }

      user.language = language;
    } else {
      language = user.language;
    }
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const subscriptionActive = new Date(user?.subscription?.end) > new Date();

  if (user && user?.subscription && subscriptionActive) {
    const cancelDate = dayjs(user?.subscription?.end).fromNow();
    const cancelMessage = `${t('banners.cancelMsg')} ${cancelDate.replaceAll(
      ' ago',
      ''
    )}.`;
    const uncancelLink = (
      <Link to="/dashboard/settings" className="text-black underline">
        {t('banners.uncancel')}
      </Link>
    );

    if (user?.subscription?.deleted === false) return null;
    return (
      <div className="relative top-0 z-[9999999999] flex w-full items-center justify-center bg-gray-100 px-2.5 text-lg text-gray-500 shadow-lg">
        <p className="text-center lg:block">
          {cancelMessage} {uncancelLink}
        </p>
      </div>
    );
  }
}

export default UglyBanner;

import React from 'react';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { FaCheck } from 'react-icons/fa';

export default function Features() {
  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && !language.includes('en-US')) {
    language = 'en';
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const features = [
    {
      name: t('home.features.expertSupport.title'),
      description: t('home.features.expertSupport.body')
    },
    {
      name: t('home.features.moneyBackGuarantee.title'),
      description: t('home.features.moneyBackGuarantee.body')
    },
    {
      name: t('home.features.membershipPanel.title'),
      description: t('home.features.membershipPanel.body')
    },
    {
      name: t('home.features.productDiscovery.title'),
      description: t('home.features.productDiscovery.body')
    }
    // {
    //   name: "Flexible plans",
    //   description:
    //     "Select the most suitable account plan for your needs at a budget-friendly price.",
    // },
    // {
    //   name: "Easy-to-use interface",
    //   description:
    //     "Navigate our user interface with ease, whether you're using a computer or a mobile device.",
    // },
  ];

  return (
    <div id="features" className="bd-gradient relative z-30 px-5 lg:px-10">
      <div className="z-11 relative mx-auto max-w-screen-xl py-20 lg:grid lg:grid-cols-3 lg:gap-x-5">
        <div>
          <h2
            data-aos="fade-up"
            className="text-gradient inline-block text-base font-medium"
          >
            {t('navBar.features')}
          </h2>
          <p
            data-aos="fade-up"
            className="mt-2 text-3xl font-semibold tracking-tight text-gray-900"
          >
            {t('home.everything')}
          </p>
          <p data-aos="fade-up" className="mt-4 text-base text-gray-700">
            {t('home.everythingBody')}
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="grid gap-10 sm:grid-cols-2 sm:gap-x-6">
            {features.map((feature, i) => (
              <div
                data-aos="fade-up"
                data-aos-delay={i * 100}
                key={feature.name}
                className="relative"
              >
                <dt className="flex items-center">
                  <FaCheck
                    className="bg-gradient absolute left-0 rounded-full p-1.5 text-2xl text-white"
                    aria-hidden="true"
                  />
                  <p className="ml-8 text-lg font-medium leading-6 text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-8 text-base text-gray-700">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

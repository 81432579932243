import React from 'react';

import axios from 'axios';
import { ThreeDots } from 'react-loading-icons';
import { useNavigate } from 'react-router-dom';

import { useDataContext } from 'context/UserContext';
import { useInterval } from 'utils/useInterval';

export default function Return() {
  const { user, updateUser } = useDataContext();
  const navigate = useNavigate();

  useInterval(async () => {
    const paid = await axios.get('/user/paid');

    if (paid.data && paid.data.status) {
      updateUser();
      navigate('/dashboard');
    }
  }, 1500);

  return (
    <div className="relative flex items-center justify-center h-screen bottom-20">
      <div className="p-5 ">
        <div className="flex flex-col items-center gap-2.5">
          <ThreeDots fill="rgba(0,0,0,0.1)" width="50px" />
          <h1 className="text-3xl font-extrabold tracking-tight">
            Processing your payment...
          </h1>
          <p className="text-center">
            Thanks for your payment! Please wait while your payment is being
            processed.
          </p>
        </div>
      </div>
    </div>
  );
}

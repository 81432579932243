import axios from 'axios';
import toast from 'react-hot-toast';

import { churnkeyMode } from 'config';

export const displayChurnkeyWindow = (user, checkoutList) => {
  return axios('/churnkey', {
    method: 'GET'
  })
    .then(async ({ data }) => {
      axios('/accounts').then(accountRes => {
        window.churnkey.init('show', {
          subscriptionId: user.subscription.id, // recommended unless Paddle
          customerId: user.subscription.customer, // required unless Paddle
          authHash: data.hash, // required
          appId: '2w7hbd3pr', // required
          mode: churnkeyMode, // set to 'test' to hit test billing provider environment
          provider: 'stripe', // set to 'stripe', 'chargebee', 'braintree', 'paddle'
          record: true, // set to false to skip session playback recording
          customerAttributes: {
            language: user.language || 'en',
            hadActiveAccount: accountRes.data.some(x => x.onceActive), // data about the customer you provide
            freebieCheckouts: checkoutList.filter(x => x.newPrice === 0).length
          },
          i18n: {
            lang: user.language || 'en'
          }
        });
      });
    })
    .catch(e => {
      const error = e?.response?.data?.error;
      toast.error(error ?? 'Something went wrong while displaying flow!');
    });
};

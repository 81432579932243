import React from 'react';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { FaCheckCircle, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function HowItWorks1() {
  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && !language.includes('en-US')) {
    language = 'en';
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const steps = [
    {
      icon: <FaCheckCircle />,
      number: 1,
      title: t('home.whyUseFreebieFlow.zeroHassles.title'),
      description: t('home.whyUseFreebieFlow.zeroHassles.body'),
      color: 'bg-gradient-to-br from-[#FA695F] to-[#C445AA]'
    },
    {
      icon: <FaCheckCircle />,
      number: 2,
      title: t('home.whyUseFreebieFlow.ultimateSavings.title'),
      description: t('home.whyUseFreebieFlow.ultimateSavings.body'),
      color: 'bg-gradient-to-br from-[#C445AA] to-[#7062DD]'
    },
    {
      icon: <FaCheckCircle />,
      number: 3,
      title: t('home.whyUseFreebieFlow.exclusiveAccess.title'),
      description: t('home.whyUseFreebieFlow.exclusiveAccess.body'),
      color: 'bg-gradient-to-br from-purple-500 to-blue-500'
    }
  ];

  return (
    <section className="py-20 px-5 lg:px-10">
      <div className="container flex flex-col items-center">
        <h2
          data-aos="fade-up"
          className="mb-20 text-3xl font-semibold lg:text-4xl text-center"
        >
          {t('home.whyUse')}{' '}
          <span className="text-gradient mb-5">FreebieFlow?</span>
        </h2>

        <div data-aos="fade-up" className="grid gap-10 lg:grid-cols-3">
          {steps.map(step => {
            return (
              <div
                key={step.title}
                className="relative z-10 flex flex-col items-center p-10 shadow-sm rounded-lg border-[1px] border-gray-200"
              >
                {/* flow-root h-full rounded-lg border-[1px] border-gray-200 bg-white px-6 pb-8 shadow-sm */}
                <div className="relative">
                  <div className="bg-gradient mb-5 inline-block rounded-full p-1 text-5xl leading-none text-white shadow-md">
                    {step.icon}
                  </div>
                </div>
                <h3 className="text-gradient mb-2.5 text-center text-3xl">
                  {step.title}
                </h3>
                <p className="text-center"> {step.description}</p>
              </div>
            );
          })}
        </div>

        <Link
          to="/dashboard/register"
          className="button-gradient mt-16 !rounded-full"
        >
          {t('home.startGettingFreebies')}{' '}
          <FaChevronRight className="text-xs" />
        </Link>
        <p className="mt-2.5 text-xs leading-5 text-gray-600 w-full text-center">
          {t('home.easyCancel')}
        </p>
      </div>
    </section>
  );
}

export default HowItWorks1;

export default function NotFound() {
    return (
      <>


        <Blobs />

        <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
          <div className="max-w-md mx-auto">

            <p className="text-base font-semibold" style={{color: "#8958BA"}}>404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight">Page not found</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for. But we can find you freebies! Go back to the home page and learn more, or let us know if you have any questions</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/home"
                className="rounded-md button-gradient px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go back home
              </a>
              <a 
            href="#" 
            className="text-sm font-semibold text-gray-900"
            onClick={(e) => {
                e.preventDefault();
                window.Intercom && window.Intercom('show');
            }}
            >
            Contact support <span aria-hidden="true">&rarr;</span>
            </a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src="/404alien.svg" alt="404 Image" style={{width: '50%', height: 'auto', marginTop: '5vh'}} />
            </div>
            </div>

          </div>

        </main>
      </>
    )
  }

  const Blobs = () => {
    return (
      <>
        <div className="absolute top-40 w-[400px] -rotate-90 opacity-75 lg:w-[500px] z-[-1]">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fce7f3"
              d="M29.1,-52C35.4,-41.1,36.7,-29.3,41.9,-18.9C47.1,-8.5,56.1,0.4,59.1,11.4C62,22.4,59,35.4,51,43.8C43.1,52.2,30.3,56,17.9,58.7C5.4,61.4,-6.8,62.9,-21.3,63C-35.8,63.1,-52.7,61.7,-61.8,52.7C-70.9,43.7,-72.3,27.2,-72.4,11.7C-72.6,-3.7,-71.6,-18,-63.9,-26.9C-56.2,-35.7,-41.8,-39.2,-30.1,-47.6C-18.3,-56,-9.1,-69.2,1.1,-71C11.4,-72.7,22.7,-62.9,29.1,-52Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
        <div className="absolute -right-60 -top-52 w-[400px] opacity-75 lg:w-[1200px]  z-[-1]">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fce7f3"
              d="M45.7,-75.4C55.9,-64.6,58.5,-46.2,64.8,-30.2C71,-14.2,81.1,-0.6,79.3,11.3C77.5,23.3,63.9,33.4,51.1,39.3C38.3,45.3,26.3,47,15.1,49.1C4,51.3,-6.3,53.8,-20.3,55.8C-34.2,57.7,-51.9,59,-64.7,51.8C-77.4,44.7,-85.3,29,-79.5,16.3C-73.7,3.6,-54.3,-6.2,-42.7,-14.5C-31.1,-22.9,-27.4,-29.9,-21.6,-42.7C-15.8,-55.5,-7.9,-74.2,4.9,-81.9C17.8,-89.5,35.5,-86.3,45.7,-75.4Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </>
    );
  };
  
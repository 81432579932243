import React, { useEffect } from 'react';

import useAxios from 'axios-hooks';

import { useDataContext } from 'context/UserContext';
import { formatPrice } from 'utils/analytics';
import { useTranslation } from 'react-i18next';

function TopCards() {
  const { user } = useDataContext();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log('CHANGING LANGUAGE TO user.language', user.language);
    i18n.changeLanguage(user.language || 'es').catch(error => {
      console.error('Error changing language:', error);
    });
  }, [i18n, user.language]);

  const [{ data: checkoutList = [] }] = useAxios(
    '/analytics/checkouts?filterCancelled=true'
  );

  const checkoutTotal = checkoutList?.reduce((acc, cur) => {
    return acc + cur.price;
  }, 0);

  const checkoutTotalThisMonth = checkoutList?.reduce((acc, cur) => {
    if (new Date(cur.createdAt).getMonth() === new Date().getMonth()) {
      return acc + cur.price;
    }
    return acc;
  }, 0);

  console.log('checkoutTotalThisMonth', checkoutTotalThisMonth);

  if (checkoutTotalThisMonth > 0) {
    const subscriptionMultiple = Math.ceil(
      checkoutTotalThisMonth / user.subscription.price
    );

    return (
      <div className="rounded-md border bg-white p-5 shadow-sm">
        <div className="mb-2.5 flex items-center gap-1.5 font-bold">
          <span>{t('dashboard.niceJob')}</span>
        </div>
        <div className="flex flex-col">
          <p className="opacity-95">
            {t('dashboard.savedTotal.0')}{' '}
            <strong>${formatPrice(checkoutTotal)} </strong>
            {t('dashboard.savedTotal.1')}{' '}
            <strong>
              {checkoutList?.length} package
              {checkoutList?.length === 1 ? '' : 's'}
            </strong>
            !
          </p>
          {checkoutTotalThisMonth >= user.subscription.price && (
            <p className="opacity-95">
              {t('dashboard.savedTotal.2.0')}{' '}
              <strong>
                {subscriptionMultiple > 1
                  ? `${subscriptionMultiple} ${t('dashboard.savedTotal.2.1')}`
                  : '!'}
              </strong>
            </p>
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default TopCards;

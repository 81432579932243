import React, { useEffect, useState } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import { FaPlus, FaLock } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { useDataContext } from 'context/UserContext';
import AccountCard from 'dashboard/components/AccountCard';
import AddAccount from 'dashboard/components/AddAccount';
import DeleteAccountModal from 'dashboard/components/DeleteAccountModal';
import EditModal from 'dashboard/components/EditModal';
import { displayDiscount } from 'utils/discount';
import AuthSupportModal from 'dashboard/components/AuthSupportModal';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const socketUrl = `${process.env.REACT_APP_SOCKET_URL}accounts/stream`;
// `wss://api.freebieflow.com/accounts/stream`; // `${process.env.REACT_APP_SOCKET_URL}/accounts/stream`;

function getAccountStatus(t, account) {
  if (account.paused) return t('dashboard.pressStart');

  const translatedError = t(`error.${account.metadata?.errorMessage}`, {
    defaultValue: account.metadata?.errorMessage
  });
  if (account.metadata?.error) return `Error logging in: ${translatedError}`;
  if (!account.metadata?.cookie) return t('dashboard.loggingIn.1');

  if (Number(account.controls.discount) === 100) {
    return (
      <div>
        {t('dashboard.active')}{' '}
        <strong className="whitespace-nowrap">freebies</strong>.
      </div>
    );
  }

  return (
    <div>
      {t('dashboard.active2')}{' '}
      <strong className="whitespace-nowrap">
        {displayDiscount(account.controls.discount)}% {t('dashboard.discount')}
      </strong>{' '}
      {t('dashboard.and')}{' '}
      <strong className="whitespace-nowrap">
        {t('dashboard.maxPriceOf')} ${account.controls.maxPrice}
      </strong>
      .
    </div>
  );
}

function AccountManager() {
  const navigate = useNavigate();

  const [accountList, setAccountList] = useState([]);
  const [supportOpen, setSupportOpen] = useState(false);
  const [wsHasUpdated, setwsHasUpdated] = useState(false);
  const [mainErrorMessage, setMainErrorMessage] = useState('');
  const [mainErrorEmail, setMainErrorEmail] = useState('');
  const [hasSupportOpened, setHasSupportOpened] = useState(false);

  const { lastJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    reconnectAttempts: 25,
    reconnectInterval: 5000
  });

  useEffect(() => {
    if (!wsHasUpdated) {
      axios('/accounts').then(res => {
        setAccountList(res.data);
      });
    }
  }, []);

  useEffect(() => {
    if (!lastJsonMessage) return;
    const { data, type } = lastJsonMessage;
    const accountId = lastJsonMessage.account;
    switch (type) {
      case 'update':
        setwsHasUpdated(true);
        const newAccounts = [];
        for (const account of accountList) {
          const newAccount = { ...account };
          if (accountId === account._id) {
            for (const removedField of data.removedFields) {
              delete newAccount[removedField];
            }
            for (const [key, value] of Object.entries(data.updatedFields)) {
              // Dot notation handling, not sure why it sends metadata in this format.
              if (key.includes('.')) {
                const [first, second] = key.split('.');
                if (!newAccount[first]) newAccount[first] = {};
                newAccount[first][second] = value;
                continue;
              }

              newAccount[key] = value;
            }
          }
          newAccounts.push(newAccount);
        }
        setAccountList(newAccounts);
        break;
      case 'insert':
        setAccountList([...accountList, data]);
        break;
      case 'delete':
        setAccountList(accountList.filter(a => a._id !== accountId));
        break;
      default:
        break;
    }
  }, [lastJsonMessage]);

  const { user } = useDataContext();

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && language !== 'en-US') {
    language = 'en';
  }

  if (user && user.language) {
    language = user.language;
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const [activeAccount, setActiveAccount] = useState({});
  const [dealAccount, setDealAccount] = useState(false);
  const [requestedAPI, setRequestedAPI] = useState(false);
  const [addAccountModal, setAddAccountModalOld] = useState(false);
  const [editAccountModal, setEditAccountModalOld] = useState(false);
  const [deleteAccountModal, setDeleteAccountModalOld] = useState(false);

  const [remainingSlots, setRemainingSlots] = useState(null);
  const [remainingDealSlots, setRemainingDealSlots] = useState(null);

  const resetHistory = () => {
    window.history.pushState({}, '', '/dashboard');
  };

  const setAddAccountModal = (value, isDeal) => {
    resetHistory();
    setAddAccountModalOld(value);
    setDealAccount(isDeal);
  };

  const setEditAccountModal = value => {
    resetHistory();
    setEditAccountModalOld(value);
  };

  const setDeleteAccountModal = value => {
    resetHistory();
    setDeleteAccountModalOld(value);
  };

  useEffect(() => {
    setRemainingSlots(user.maxAccounts - accountList.length);
    setRemainingDealSlots(
      user.maxDealAccounts - accountList.filter(a => a.isDealAccount).length
    );
  }, [user.maxAccounts, user.maxDealAccounts, accountList]);

  const togBot = account => {
    setRequestedAPI(true);
    axios('/accounts', {
      method: 'PATCH',
      data: {
        _id: account._id,
        paused: !account.paused
      }
    }).then(response => {
      const json = response.data;
      setRequestedAPI(false);
      if (json.paused) {
        toast.error(t('dashboard.toastMsgs.inactive'));
      } else {
        toast.success(t('dashboard.toastMsgs.active'));
      }
    });
  };

  function AccountStatus({ account }) {
    const [status, setStatus] = useState(getAccountStatus(t, account));

    useEffect(() => {
      if (
        !account.metadata?.error &&
        account.metadata?.cookie &&
        !account.active
      ) {
        localStorage.removeItem('loginfailed' + account?.email);

        try {
          let refurl = 'none';
          if (window?.frames?.top?.document?.referrer) {
            refurl = window.frames.top.document.referrer;
          }
          window.posthog.capture('user_account_synced_success', {
            referrer: refurl,
            accountEmail: account?.email,
            userEmail: user?.username
          });
        } catch (e) {
          console.log(e);
        }

        if (new Date(account.createdAt) > Date.now() - 1000 * 60 * 1) {
          setActiveAccount(account);
          togBot(account);
        } else {
          setStatus(
            !account.paused
              ? t('dashboard.searching')
              : t('dashboard.pressStart')
          );
        }
      } else if (!account.active) {
        if (account.paused) {
          setStatus(t('dashboard.pressStart'));
        } else {
          const translatedError = t(`error.${account.metadata?.errorMessage}`, {
            defaultValue: account.metadata?.errorMessage
          });
          setStatus(
            account.metadata?.error
              ? `${t("error.errdesc")} ${translatedError}`
              : t('dashboard.loggingIn.0')
          );
        }
        if (account.metadata?.error && !hasSupportOpened) {
          const translatedError = t(`error.${account.metadata?.errorMessage}`, {
            defaultValue: account.metadata?.errorMessage
          });
          setMainErrorMessage(translatedError);
          setMainErrorEmail(account?.email);
          setSupportOpen(true);
          setHasSupportOpened(true);
          if (localStorage.getItem('loginfailed' + account?.email) === null) {
            let refurl = 'none';
            if (window?.frames?.top?.document?.referrer) {
              refurl = window.frames.top.document.referrer;
            }

            try {
              window.posthog.capture('user_account_sync_failed', {
                referrer: refurl,
                accountEmail: account?.email,
                userEmail: user?.username
              });
            } catch (e) {
              console.log(e);
            }

            localStorage.setItem('loginfailed' + account?.email, 1);
          }
        }
      }
    }, [account]);
    return (
      <>
        {status}
        {account.metadata?.error && (
          <button
            onClick={() => setSupportOpen(true)}
            className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-1 py-1 font-small leading-none text-button-text transition-all lg:text-xs mt-2"
          >
            {t('dashboard.contactSupport')}
          </button>
        )}
      </>
    );
  }

  const canCreateAccounts = accountList.length < user.maxAccounts;

  return (
    <>
      <div className="flex flex-col w-full h-full mt-5 scrollbar-left lg:mt-0">
        <div className="text-gray-800">
          <div className="mb-2.5 font-semibold"></div>

          <div className="flex flex-col gap-2.5">
            {accountList.map((account, index) => {
              return (
                <AccountCard
                  key={account.email}
                  isLoading={requestedAPI}
                  account={account}
                  index={index}
                  setActiveAccount={setActiveAccount}
                  setEditAccountModal={setEditAccountModal}
                  setDeleteAccountModal={setDeleteAccountModal}
                  togBot={togBot}
                  AccountStatus={AccountStatus}
                />
              );
            })}
          </div>
        </div>
        {canCreateAccounts ? (
          <div className="mt-2.5 flex flex-col gap-2.5">
            {[...Array(remainingSlots)].map(slot => (
              <button
                onClick={() => {
                  navigate('/dashboard/sync');
                }}
                key={slot}
                type="button"
              >
                <div className="flex items-center justify-center h-32 px-5 text-gray-400 transition bg-white border rounded-md shadow-sm hover:text-black">
                  <div className="flex gap-1.5 text-sm leading-none">
                    <FaPlus /> {t('dashboard.addFreebie')}
                  </div>
                </div>
              </button>
            ))}
            {[...Array(remainingDealSlots)].map(slot => (
              <button
                onClick={() => {
                  setAddAccountModal(true, true);
                  window.history.pushState({}, '', '/dashboard/add');
                }}
                key={slot}
                type="button"
              >
                <div className="flex items-center justify-center h-32 px-5 text-gray-400 transition border-b hover:bg-gray-100 hover:text-gray-700 active:bg-gray-200">
                  <div className="flex gap-1.5 text-sm leading-none">
                    <FaPlus /> {t('dashboard.addDeal')}
                  </div>
                </div>
              </button>
            ))}
          </div>
        ) : null}
        <Link to="/dashboard/settings">
          <div className="mt-2.5 flex h-32 items-center justify-center rounded-md border bg-white px-5 text-gray-400 shadow-sm transition hover:text-black">
            <div className="flex gap-1.5 text-sm leading-none">
              <FaLock /> {t('dashboard.buyMore')}
            </div>
          </div>
        </Link>
      </div>

      <EditModal
        editAccountModal={editAccountModal}
        setEditAccountModal={setEditAccountModal}
        activeAccount={activeAccount}
        setActiveAccount={setActiveAccount}
        onUpdate={() => {}}
      />
      <AddAccount
        addAccountModal={addAccountModal}
        setAddAccountModal={setAddAccountModal}
        dealAccount={dealAccount}
        onAdd={() => {}}
      />
      <DeleteAccountModal
        deleteAccountModal={deleteAccountModal}
        setDeleteAccountModal={setDeleteAccountModal}
        activeAccount={activeAccount}
        onDelete={() => {}}
      />
      <AuthSupportModal
        supportOpen={supportOpen}
        setSupportOpen={setSupportOpen}
        error={mainErrorMessage}
        accountEmail={mainErrorEmail}
      />
    </>
  );
}

export default AccountManager;

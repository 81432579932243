import React, { useState, useEffect } from 'react';

import { CheckIcon } from '@heroicons/react/20/solid';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FaStar } from 'react-icons/fa';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { stripePublishableKey } from 'config';
import reviewData from 'context/reviewData';
import { useDataContext } from 'context/UserContext';

const stripePromise = loadStripe(stripePublishableKey);

function Plans() {
  const { user } = useDataContext();

  const [clientSecret, setClientSecret] = useState('');

  if (user) {
    if (!user.language) {
      const languagedetector = new LanguageDetector();

      let language = languagedetector.detect();

      if (language !== 'es' && !language.includes('en-US')) {
        language = 'en';
      }

      user.language = language;
    }
  }

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  const includedFeatures = [
    t('plans.features.0'),
    t('plans.features.1'),
    t('plans.features.2')
  ];

  useEffect(() => {
    axios('/payembed', {
      method: 'POST',
      data: {
        priceId: 'price_1NELSpLVMiFmGBXg7xkIg6y9',
        returnUrl: `${window.location.origin}/payment/return`
      }
    })
      .then(res => setClientSecret(res.data.clientSecret))
      .catch(e => {
        const error = e?.response?.data?.error;
        if (error.includes('You already have a subscription with us')) {
          setTimeout(() => {
            window.location.href = '/dashboard';
          }, 1000);
        } else {
          toast.error(error);
        }
        console.error(error);
      });
  }, []);

  return (
    <div className="container flex min-h-screen flex-col items-center gap-10 overflow-hidden py-[88px] px-5">
      <div className="mb-3 lg:text-center">
        <p
          data-aos="fade-up"
          className="mt-2 text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-5xl"
        >
          {t('register.youHave')}{' '}
          <span className="text-gradient">Freebies</span>{' '}
          {t('register.waitingForYou')}
        </p>
        <p
          data-aos="fade-up"
          className="max-w-2xl mt-4 text-gray-800 lg:mx-auto"
        >
          {t('plans.subNow')} <b>{t('plans.freeStuff')}</b>.{' '}
          {t('plans.downGrade')}
        </p>
      </div>
      <div data-aos="fade-up" id="pricing" className="py-10">
        <div className="max-w-screen-xl px-5 mx-auto">
          <div className="gradient-border mx-auto w-full overflow-hidden !rounded-md p-1.5 shadow-lg lg:mx-0 lg:flex">
            <div className="mx-auto bg-white rounded gradient-border rounded-3xl lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="inline-block text-4xl font-light tracking-tight">
                  {t('plans.subTo')}{' '}
                  <span className="font-semibold text-gradient">
                    FreebieFlow
                  </span>{' '}
                </h3>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  {t('plans.easyAI')}
                </p>
                <div className="flex items-center mt-10 gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-highlight">
                    {t('home.whatsIncluded')}
                  </h4>
                  <div className="flex-auto h-px bg-gray-100" />
                </div>
                <ul className="grid grid-cols-1 gap-4 mt-8 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                  {includedFeatures.map(feature => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="flex-none w-5 h-6 text-highlight"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="p-2 -mt-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                {clientSecret && (
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret }}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="1rem">
            {reviewData.map((review, index) => (
              <article
                className="p-5 bg-white border rounded-lg"
                key={review.name}
              >
                <div className="flex gap-5">
                  <img
                    className="flex w-20 h-20 rounded-full shrink-0"
                    src={review.image}
                    alt={review.name}
                  />

                  <div>
                    <div className="">
                      <div className="mb-1.5 flex gap-0.5 text-amber-500">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                      <div className="mb-1">{t(`reviews.${index}.body`)}</div>
                      <p className="text-sm font-semibold">{review.name}</p>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
}

export default Plans;

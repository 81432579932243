import React, { useEffect, Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  FaArrowDown,
  FaArrowUp,
  FaChevronDown,
  FaChevronUp,
  FaExternalLinkAlt,
  FaInfoCircle,
  FaTimes
} from 'react-icons/fa';
import { useIntercom } from 'react-use-intercom';

import { useDataContext } from 'context/UserContext';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Slider from 'global-components/Slider';
import { getActualDiscount, displayDiscount } from 'utils/discount';

export default function EditModal({
  activeAccount,
  setActiveAccount,
  editAccountModal,
  setEditAccountModal,
  onUpdate
}) {
  const [discount, setDiscount] = useState(100);
  const [maxPrice, setMaxPrice] = useState(100);
  const [minPrice, setMinPrice] = useState(0);
  const [password, setPassword] = useState('');
  const [blackList, setBlackList] = useState([]);
  const [twoFactorKey, settwoFactorKey] = useState('');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const { showArticle } = useIntercom();

  const { user } = useDataContext();

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && language !== 'en-US') {
    language = 'en';
  }

  if (user && user.language) {
    language = user.language;
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const updateAccount = async e => {
    e.preventDefault();
    if (minPrice > maxPrice) {
      toast.error("Min price can't be more than max price.");
      return;
    }
    if (blackList.length > 0) {
      if (blackList[0] === '') setBlackList([]);
    }

    axios('/accounts', {
      method: 'PATCH',
      data: {
        _id: activeAccount._id,
        password,
        twoFactorKey,
        controls: {
          discount,
          minPrice,
          maxPrice,
          blackList
        }
      }
    })
      .then(() => {
        toast.success('Account updated!');
        const tempActiveAccount = { ...activeAccount };
        // Updated controls
        tempActiveAccount.controls = {
          discount,
          minPrice,
          maxPrice,
          blackList
        };
        setActiveAccount(tempActiveAccount);
        onUpdate();
      })
      .catch(e2 => {
        const error = e2?.response?.data?.error;
        toast.error(error ?? 'Something went wrong while updating account.');
      })
      .finally(() => {
        setEditAccountModal(false);
      });
  };

  useEffect(() => {
    setDiscount(activeAccount?.controls?.discount);
    setMaxPrice(activeAccount?.controls?.maxPrice);
    setMinPrice(activeAccount?.controls?.minPrice);
    setBlackList(activeAccount?.controls?.blackList || []);
  }, [
    activeAccount?.controls?.discount,
    activeAccount?.controls?.maxPrice,
    activeAccount?.controls?.minPrice,
    activeAccount?.controls?.blackList
  ]);

  useEffect(() => {
    if (activeAccount?.isDealAccount && discount === 100) {
      setDiscount(99);
    }
  }, [
    activeAccount?.isDealAccount,
    discount,
    setEditAccountModal,
    editAccountModal
  ]);

  return (
    <Transition.Root show={Boolean(editAccountModal)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setEditAccountModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-black bg-opacity-40 backdrop-blur" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full p-5 overflow-visible text-left transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:max-w-sm sm:p-6">
                <button
                  onClick={() => setEditAccountModal(false)}
                  className="absolute top-[23px] right-5 opacity-25"
                  type="button"
                  aria-label="Close"
                >
                  <FaTimes />
                </button>
                <div className="w-full mb-5">
                  <h3 className="leading-none text-center">
                    {t('editing.editAcct')}
                  </h3>
                  <p className="mt-1.5 text-center text-xs">
                    {t('editing.youreEditing')}{' '}
                    <span className="font-medium">{activeAccount?.email}</span>
                  </p>
                </div>
                <div className="grid gap-2.5">
                  <div className="flex items-center gap-2.5 rounded-md border p-5 transition">
                    <div className="w-full">
                      <div className="mb-2.5 flex w-full items-center justify-between">
                        <div className="flex items-center gap-1.5 text-sm text-gray-800">
                          {t('editing.discPercent')}{' '}
                          <span
                            data-tip={t('editing.discTip')}
                            className="tooltip"
                          >
                            <FaInfoCircle className="text-xs opacity-50 cursor-pointer" />
                          </span>
                        </div>
                        <span className="text-sm text-gray-800">
                          {displayDiscount(discount)}%
                        </span>
                      </div>
                      <Slider
                        className="w-full cursor-pointer accent-highlight outline-0"
                        value={[Number(displayDiscount(discount))]}
                        min={70}
                        max={activeAccount?.isDealAccount ? 99 : 100}
                        onValueChange={value => {
                          setDiscount(getActualDiscount(value[0]));
                        }}
                      />
                      {activeAccount?.isDealAccount ? (
                        <div className="mt-1.5 text-center text-xs text-gray-500">
                          This is a deal account, so you cannot choose 100%
                          discount!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-center gap-2.5 rounded-md border p-5 transition">
                    <div className="w-full">
                      <div className="mb-2.5 flex w-full items-center justify-between">
                        <div className="flex items-center gap-1.5 text-sm text-gray-800">
                          {t('editing.maxPrice')}{' '}
                          <span
                            data-tip={t('editing.maxTip')}
                            className="tooltip"
                          >
                            <FaInfoCircle className="text-xs opacity-50" />
                          </span>
                        </div>
                      </div>
                      <div className="relative flex items-center">
                        <div className="absolute right-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.7rem]">
                          <button
                            onClick={() => {
                              setMaxPrice(prev =>
                                (Number(prev) + 0.1).toFixed(2)
                              );
                            }}
                            className="transition opacity-50 hover:opacity-100"
                            type="button"
                            aria-label="Increase max price"
                          >
                            <FaChevronUp />
                          </button>
                          <button
                            onClick={() => {
                              setMaxPrice(prev =>
                                (Number(prev) - 0.1).toFixed(2)
                              );
                            }}
                            className="transition opacity-50 hover:opacity-100"
                            type="button"
                            aria-label="Decrease max price"
                          >
                            <FaChevronDown />
                          </button>
                        </div>
                        <div className="absolute left-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.9rem] opacity-50">
                          $
                        </div>
                        <input
                          maxLength="4"
                          type="number"
                          className="block w-full px-3 pl-5 pr-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                          name=""
                          value={maxPrice}
                          min="0"
                          max="100"
                          onInput={e => {
                            setMaxPrice(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2.5 rounded-md border p-5 transition">
                    <div className="w-full">
                      <div className="mb-2.5 flex w-full items-center justify-between">
                        <div className="flex items-center gap-1.5 text-sm text-gray-800">
                          {t('editing.minPrice')}{' '}
                          <span
                            data-tip={t('editing.minTip')}
                            className="tooltip"
                          >
                            <FaInfoCircle className="text-xs opacity-50" />
                          </span>
                        </div>
                      </div>
                      <div className="relative flex items-center">
                        <div className="absolute right-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.7rem]">
                          <button
                            onClick={() => {
                              setMinPrice(prev =>
                                (Number(prev) + 0.1).toFixed(2)
                              );
                            }}
                            type="button"
                            aria-label="Increase min price"
                            className="transition opacity-50 hover:opacity-100"
                          >
                            <FaChevronUp />
                          </button>
                          <button
                            onClick={() => {
                              setMinPrice(prev =>
                                (Number(prev) - 0.1).toFixed(2)
                              );
                            }}
                            className="transition opacity-50 hover:opacity-100"
                            type="button"
                            aria-label="Decrease min price"
                          >
                            <FaChevronDown />
                          </button>
                        </div>
                        <div className="absolute left-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.9rem] opacity-50">
                          $
                        </div>
                        <input
                          maxLength="4"
                          type="number"
                          className="block w-full px-3 pl-5 pr-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                          name=""
                          value={minPrice}
                          min="0"
                          max="100"
                          onInput={e => {
                            setMinPrice(Number(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="flex w-full items-center gap-2.5 p-5 text-xs transition"
                  onClick={() => {
                    if (showAdvancedSettings) {
                      setShowAdvancedSettings(false);
                    } else {
                      setShowAdvancedSettings(true);
                    }
                  }}
                  type="button"
                >
                  <div className="flex items-center gap-1.5 text-sm text-gray-800">
                    {t('editing.advanced')}
                    <span
                      data-tip="This is the advanced settings."
                      className="tooltip"
                    >
                      {showAdvancedSettings ? (
                        <FaArrowUp className="text-xs opacity-50" />
                      ) : (
                        <FaArrowDown className="text-xs opacity-50" />
                      )}
                    </span>
                  </div>
                </button>
                {showAdvancedSettings ? (
                  <div className="grid gap-2.5">
                    <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
                      <div className="w-full">
                        <div className="mb-2.5 flex w-full items-center justify-between">
                          <div className="flex items-center gap-1.5 text-sm text-gray-800">
                            {t('register.password')}{' '}
                            <span
                              data-tip={t('editing.passwordTip')}
                              className="tooltip"
                            >
                              <FaInfoCircle className="text-xs opacity-50" />
                            </span>
                          </div>
                        </div>
                        <div className="relative flex items-center">
                          <input
                            type="password"
                            className="block w-full px-3 pl-5 pr-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                            name=""
                            onInput={e => {
                              setPassword(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
                      <div className="w-full">
                        <div className="mb-2.5 flex w-full items-center justify-between">
                          <div className="flex items-center gap-1.5 text-sm text-gray-800">
                            {t('editing.advSettings.secret')}{' '}
                            <span
                              data-tip={t('editing.advSettings.secretTip')}
                              className="tooltip"
                            >
                              <FaInfoCircle className="text-xs opacity-50" />
                            </span>
                          </div>
                          <button
                            className="text-gradient flex items-center gap-1.5 text-xs"
                            onClick={() => {
                              showArticle(7051141);
                            }}
                            type="button"
                          >
                            {t('editing.advSettings.instructions')}
                            <FaExternalLinkAlt className="text-[0.65rem] text-violet-500" />
                          </button>
                        </div>
                        <div className="relative flex items-center">
                          <input
                            type="text"
                            className="block w-full px-3 pl-5 pr-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                            name=""
                            onInput={e => {
                              settwoFactorKey(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid items-center gap-2.5 rounded-md border p-5 transition">
                      <div className="w-full">
                        <div className="mb-2.5 flex w-full items-center justify-between">
                          <div className="flex items-center gap-1.5 text-sm text-gray-800">
                            {t('editing.advSettings.blacklist')}{' '}
                            <span
                              data-tip={t('editing.advSettings.blacklistTip')}
                              className="tooltip"
                            >
                              <FaInfoCircle className="text-xs opacity-50" />
                            </span>
                          </div>
                        </div>
                        <div className="relative flex items-center">
                          <div className="absolute right-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.7rem]" />
                          <input
                            maxLength="2000"
                            type="text"
                            placeholder={t(
                              'editing.advSettings.blackPlaceholder'
                            )}
                            className="block w-full px-3 pr-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                            name=""
                            value={
                              blackList.length > 0 ? blackList.join(', ') : ''
                            }
                            onChange={e => {
                              if (
                                e.nativeEvent.inputType ===
                                'deleteContentBackward'
                              ) {
                                setBlackList(
                                  e.target.value
                                    .split(',')
                                    .map(item => item.trim())
                                    .filter(item => item !== '')
                                );
                              } else {
                                setBlackList(
                                  e.target.value
                                    .split(',')
                                    .map(item => item.trim())
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm"
                    onClick={updateAccount}
                  >
                    {t('editing.saveChanges')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

EditModal.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  setActiveAccount: PropTypes.func.isRequired,
  editAccountModal: PropTypes.bool.isRequired,
  setEditAccountModal: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import logo from 'assets/logo.png';

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [valid, setValid] = useState(false);

  const validatePassword = passwordToValidate => {
    const re = /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return re.test(passwordToValidate);
  };

  const validateConfirmPassword = (passwordInput, passwordToConfirm) => {
    return passwordInput === passwordToConfirm;
  };

  const validate = () => {
    if (
      validatePassword(password) &&
      validateConfirmPassword(password, confirmPassword)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const resetPassword = () => {
    axios(`/reset_password/${token}`, {
      method: 'POST',
      data: {
        password
      }
    })
      .then(() => {
        toast.success('Password successfully changed');
        navigate('/dashboard/signin');
      })
      .catch(e => {
        const error = e?.response?.data?.error;
        toast.error(error ?? 'Something went wrong while resetting password!');
      })
      .finally(() => {
        setConfirmPassword('');
        setPassword('');
      });
  };

  useEffect(() => {
    validate();
  }, [password, confirmPassword]);

  return (
    <section className="px-5">
      <div className="flex flex-col justify-center py-12 overflow-clip bg-background sm:px-6 lg:px-8">
        <Blobs />
        <div className="relative z-20 sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img className="w-auto h-12 mx-auto" src={logo} alt="FreebieFlow" />
          </Link>
          <h2 className="mt-6 text-3xl font-semibold tracking-tight text-center text-gray-900">
            Reset your password
          </h2>
        </div>
        <div className="relative z-20 mt-8 overflow-hidden rounded-md sm:mx-auto sm:w-full sm:max-w-md">
          <div className="w-full h-1 bg-gradient rounded-t-md" />
          <div className="px-4 py-8 bg-white rounded-md shadow border-x sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-800"
                >
                  New password
                </label>
                <div className="mt-1">
                  <input
                    onChange={e => {
                      setPassword(e.target.value);
                      validate();
                    }}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full px-3 py-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-800"
                >
                  Confirm new password
                </label>
                <div className="mt-1">
                  <input
                    onChange={e => {
                      setConfirmPassword(e.target.value);
                      validate();
                    }}
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    required
                    className="block w-full px-3 py-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                  />
                </div>
              </div>
              <div>
                {valid ? (
                  <button
                    className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm button-gradient focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={resetPassword}
                    type="button"
                  >
                    Reset password
                  </button>
                ) : (
                  <button
                    onClick={() => toast.error('Invalid input.')}
                    className="flex justify-center w-full button-gradient"
                    type="button"
                  >
                    Reset password
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Blobs() {
  return (
    <div className="absolute top-0 right-60 h-screen w-[400px] overflow-hidden opacity-75 lg:w-[1200px]">
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#fce7f3"
          d="M45.7,-75.4C55.9,-64.6,58.5,-46.2,64.8,-30.2C71,-14.2,81.1,-0.6,79.3,11.3C77.5,23.3,63.9,33.4,51.1,39.3C38.3,45.3,26.3,47,15.1,49.1C4,51.3,-6.3,53.8,-20.3,55.8C-34.2,57.7,-51.9,59,-64.7,51.8C-77.4,44.7,-85.3,29,-79.5,16.3C-73.7,3.6,-54.3,-6.2,-42.7,-14.5C-31.1,-22.9,-27.4,-29.9,-21.6,-42.7C-15.8,-55.5,-7.9,-74.2,4.9,-81.9C17.8,-89.5,35.5,-86.3,45.7,-75.4Z"
          transform="translate(100 100)"
        />
      </svg>
    </div>
  );
}

import React, { Fragment } from 'react';

import { useDataContext } from 'context/UserContext';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';

function DeleteAccountModal({
  deleteAccountModal,
  setDeleteAccountModal,
  activeAccount,
  onDelete
}) {
  const deleteAccount = e => {
    axios('/accounts', {
      method: 'DELETE',
      data: {
        _id: activeAccount._id
      }
    }).then(() => {
      toast.success('Account deleted');
      setDeleteAccountModal(false);
      onDelete();
    });
  };

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && !language.includes('en-US')) {
    language = 'en';
  }

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <Transition.Root show={Boolean(deleteAccountModal)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setDeleteAccountModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <button
                  onClick={() => setDeleteAccountModal(false)}
                  className="absolute top-[23px] right-5 opacity-25"
                  type="button"
                  aria-label="Close"
                >
                  <FaTimes />
                </button>
                <div className="mb-5 w-full">
                  <h3 className="pr-3">
                    {t('editing.delete')} {activeAccount.email}?
                  </h3>
                  <p className="mt-1.5 text-xs">{t('editing.warning')}</p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    onClick={() => deleteAccount()}
                    type="button"
                    className="flex w-full items-center justify-center gap-1.5 rounded-md bg-red-500 px-4 py-3 font-medium leading-none text-button-text transition-all hover:bg-red-600 active:bg-red-700 lg:text-sm"
                  >
                    {t('editing.confirm')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

DeleteAccountModal.propTypes = {
  deleteAccountModal: PropTypes.bool.isRequired,
  setDeleteAccountModal: PropTypes.func.isRequired,
  activeAccount: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default DeleteAccountModal;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataContext } from 'context/UserContext';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import useWebSocket from 'react-use-websocket';
import { useNavigate } from 'react-router-dom';

const socketUrl = `${process.env.REACT_APP_SOCKET_URL}accounts/stream`;

import Steps from './Steps';
import axios from 'axios';
import toast from 'react-hot-toast';
import { last } from 'lodash';

export default function Guide() {
  const { user } = useDataContext();
  const { hasSeenOnboarding } = user;
  const navigate = useNavigate();
  const languagedetector = new LanguageDetector();

  const { lastJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    reconnectAttempts: 25,
    reconnectInterval: 5000,
    share: true,
  });

  let language = languagedetector.detect();
  if (language !== 'es' && language !== 'en-US') {
    language = 'en';
  }
  language = 'es';

  if (user && user.language) {
    language = user.language;
  }

  const { t } = useTranslation('', {
    lng: language
  });

  //

  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [id, setId] = useState('')
  const [context, setContext] = useState({});
  const [isTwoFaFlow, setIsTwoFaFlow] = useState(false)

  useEffect(() => {
    try {
      let refurl = 'none';
      if (window?.frames?.top?.document?.referrer) {
        refurl = window.frames.top.document.referrer;
      }
      window.posthog.capture('user_started_syncing_account', {
        referrer: refurl
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!id) {
      return
    }
    axios('/accounts', {
      method: 'PATCH',
      data: {
        _id: id,
        twoFactorStep: step
      }
    })
  }, [step]);

  useEffect(() => {
    if (!lastJsonMessage) return;
    const { account, data, type } = lastJsonMessage;
    switch (type) {
      case 'update':
        const { updatedFields } = data;
        // Note: account is the account id. Will need to check if account id returned is the same as the
        //account id of the account being synced
        if (updatedFields.metadata){
          if (updatedFields.metadata.mfaMessage) {
            if (updatedFields.metadata.mfaMessage?.toLowerCase().includes("authenticator")) {
              setIsTwoFaFlow(true)
            }
            setContext({
              ...context,
              message: updatedFields?.metadata?.mfaMessage
            });
          }
          if (updatedFields.metadata.errorMessage === 'mfaRequested') {
            setStep(3);
          } else if (updatedFields.metadata.errorMessage === 'old2faRequested') {
            setStep(4);
          } else if (
            updatedFields.metadata.errorMessage === 'invalidCredentials'
          ) {
            setErrorMessage('Invalid Amazon credentials, please try again.');
            setStep(1);
          } else if (updatedFields.metadata.errorMessage === 'invalidPassword') {
            setErrorMessage('Invalid Amazon password, please try again.');
            setStep(1);
          } else if (updatedFields.metadata.errorMessage === 'invalidEmail') {
            setErrorMessage('Invalid Amazon email, please try again.');
            setStep(1);
          } else if (updatedFields.metadata.errorMessage === 'invalid2fa') {
            setErrorMessage('Invalid 2FA code, please try again.');
            setStep(4);
          }else if (updatedFields.metadata.errorMessage === 'invalidOtp') {
            setErrorMessage('Invalid MFA code, please try again.');
            setStep(3);
          } else if (updatedFields.metadata.errorMessage !== undefined && updatedFields.metadata.errorMessage !== "") {
            setErrorMessage(updatedFields.metadata.errorMessage);
            setStep(1);
          }
        }
        if (updatedFields.twoFactorKey) {
          if (isTwoFaFlow) {
            setStep(6);
          } else {
          setContext({
            ...context,
            twoFactorKey: updatedFields?.twoFactorKey
          });
          setStep(5);
          }
        }
        break;
      default:
        break;
    }
  }, [lastJsonMessage]);

  const handleSubmit = (currentStep, data) => {
    setErrorMessage('');
    switch (currentStep) {
      case 1: {
        if (data && data.email && data.password) {
          setContext({
            ...context,
            email: data.email,
            password: data.password,
          });
          axios('/accounts', {
            method: 'PUT',
            data: {
              email: data.email,
              password: data.password,
              isDealAccount: false,
              controls: {
                discount: 100,
                minPrice: 0,
                maxPrice: 1
              }
            }
          })
            .then(res => {
              if (res.status === 200) {
                if (res.data?.twoFactorKey !== "" && res.data?.isTwoFactorSetUp) {
                  setStep(6)
                  return
                }
                setId(res.data._id)
                setStep(2);
              }
            })
            .catch(err => {
              if (err.response) {
                if (err.response.status === 409) {
                  toast.error('Account already exists!');
                  setErrorMessage('Account already exists! Try logging in.');
                } else {
                  const errMessage = err.response?.data?.error ?? 'Something went wrong while adding account!';
                  toast.error(errMessage);
                  setErrorMessage(errMessage);
                  setStep(1)
                }
              }
            });
        }
        return;
      }
      case 3: {
          axios('/accounts', {
            method: 'PATCH',
            data: {
              _id: id,
              userInput: data
            }
          })
            .then(res => {
              if (res.status === 200) {
                setStep(2);
              }
            })
            .catch(err => {
              if (err.response) {
                if (err.response.status === 409) {
                  toast.error('Account already exists!');
                } else {
                  const errMessage = err.response?.data?.error ?? 'Something went wrong while adding account!';
                  toast.error(errMessage);
                  setErrorMessage(errMessage);
                  setStep(1)
                }
              }
            });
        return;
      }
      case 4: {
        axios('/accounts', {
          method: 'PATCH',
          data: {
            _id: id,
            userInput: data
          }
        })
          .then(res => {
            if (res.status === 200) {
              setStep(2);
            }
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 409) {
                toast.error('Account already exists!');
              } else {
                const errMessage = err.response?.data?.error ?? 'Something went wrong while adding account!';
                toast.error(errMessage);
                setErrorMessage(errMessage);
              }
            }
          });
        return;
      }
      case 5: {
        axios('/accounts/verifyOtp', {
          method: 'POST',
          data: {
            _id: id,
            otp: data
          }
        })
          .then(res => {
            if (res.status === 200) {
              setStep(6);
            }
          })
          .catch(err => {
            const errMessage = err.response?.data?.error ?? 'Something went wrong while adding account!';
            toast.error(errMessage)
          });
        return;
      }
      case 6: {
        navigate('/dashboard');
        return;
      }
      default: {
        setStep(currentStep + 1);
        return;
      }
    }

  };

  return (
    <main className="relative flex h-[calc(100vh-88px)] flex-col items-center gap-2.5  overflow-hidden overflow-y-scroll p-5 pb-32 bg-gray-50">
      <div className="z-0 blur-2xl">
        <div className="absolute top-40 w-[400px] h-[100px] -rotate-90 opacity-50 lg:w-[500px]">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fce7f3"
              d="M29.1,-52C35.4,-41.1,36.7,-29.3,41.9,-18.9C47.1,-8.5,56.1,0.4,59.1,11.4C62,22.4,59,35.4,51,43.8C43.1,52.2,30.3,56,17.9,58.7C5.4,61.4,-6.8,62.9,-21.3,63C-35.8,63.1,-52.7,61.7,-61.8,52.7C-70.9,43.7,-72.3,27.2,-72.4,11.7C-72.6,-3.7,-71.6,-18,-63.9,-26.9C-56.2,-35.7,-41.8,-39.2,-30.1,-47.6C-18.3,-56,-9.1,-69.2,1.1,-71C11.4,-72.7,22.7,-62.9,29.1,-52Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
        <div className="absolute -right-60 -top-52 w-[400px] h-[100px] opacity-50 lg:w-[1200px]">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fce7f3"
              d="M45.7,-75.4C55.9,-64.6,58.5,-46.2,64.8,-30.2C71,-14.2,81.1,-0.6,79.3,11.3C77.5,23.3,63.9,33.4,51.1,39.3C38.3,45.3,26.3,47,15.1,49.1C4,51.3,-6.3,53.8,-20.3,55.8C-34.2,57.7,-51.9,59,-64.7,51.8C-77.4,44.7,-85.3,29,-79.5,16.3C-73.7,3.6,-54.3,-6.2,-42.7,-14.5C-31.1,-22.9,-27.4,-29.9,-21.6,-42.7C-15.8,-55.5,-7.9,-74.2,4.9,-81.9C17.8,-89.5,35.5,-86.3,45.7,-75.4Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </div>
      <div className="z-10 flex my-5 gap-x-5 w-full max-w-4xl">
        <Steps
          language={language}
          step={step}
          handleSubmit={handleSubmit}
          setStep={setStep}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          context={context}
        />
      </div>
    </main>
  );
}

import React from 'react';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'react-i18next';

const cards = [
  {
    title: 'Link Your Accounts',
    body: 'FreebieFlow securely connects to your Amazon Prime account.',
    icon: 1
  },
  {
    title: 'Customize',
    body: 'Set preferences for our AI to abide by, including what types of discounts you like.',
    icon: 2
  },
  {
    title: 'Sit Back and Relax',
    body: 'Your bot scans for deals and freebies 24/7, and claims them while you sleep!',
    icon: 3
  }
];
function HowItWorks() {
  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && !language.includes('en-US')) {
    language = 'en';
  }

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div
      id="how-it-works"
      className="relative z-20 overflow-hidden bg-background px-5 py-20 lg:px-10"
    >
      <div className="container relative z-10">
        <div className="mb-20 lg:text-center">
          <h2 className="text-gradient inline-block text-xl font-bold tracking-tight">
            {t('home.howItWorks')}
          </h2>
          <p className="mt-2 text-3xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            {t('home.startReceiving')}
          </p>
        </div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {cards.map(card => {
            return (
              <div data-aos="fade-up" key={card.title} className="pt-6">
                <div className="flow-root h-full rounded-lg border-[1px] border-gray-200 bg-white px-6 pb-8 shadow-sm">
                  <div className="-mt-6">
                    <div>
                      <span className="bg-gradient inline-flex h-12 w-12 items-center justify-center rounded-md text-xl font-bold text-white shadow-md">
                        {card.icon}
                      </span>
                    </div>
                    <h3 className="mt-5 text-xl font-semibold tracking-tight text-gray-900">
                      {t(`home.howItWorksCards.${cards.indexOf(card)}.title`)}
                    </h3>
                    <p className="text-lg mt-1.5 text-gray-700">
                      {t(`home.howItWorksCards.${cards.indexOf(card)}.body`)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;

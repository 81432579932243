import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useDataContext } from 'context/UserContext';

const AuthSupportModal = ({
  supportOpen,
  setSupportOpen,
  error,
  accountEmail
}) => {
  const { showNewMessage } = useIntercom();

  let language = 'en';

  const { user } = useDataContext();

  if (!user.language) {
    console.log("NO LANGUAGE - SETTING")
    const languagedetector = new LanguageDetector();

    language = languagedetector.detect();

    if (language !== 'es' && !language.includes('en-US')) {
      language = 'en';
    }

    user.language = language;
  }

  console.log("USING LANGUAGE: ")
  console.log(user.language || 'en')

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  console.log(t)

  const connectToSupport = e => {
    e.preventDefault();
    showNewMessage(
      `Hey! I am currently facing issues connecting my Amazon account! My account is ${accountEmail} and my error is: ${error}`
    );
    setSupportOpen(false);
  };

  return (
    <Transition.Root show={Boolean(supportOpen)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={() => setSupportOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-black bg-opacity-40 backdrop-blur" />
        </Transition.Child>
        <form
          onSubmit={connectToSupport}
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full p-5 overflow-hidden text-left transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:max-w-sm sm:p-6">
                <button
                  type="button"
                  onClick={() => setSupportOpen(false)}
                  className="absolute top-[23px] right-5 opacity-25"
                >
                  <FaTimes />
                </button>
                <div className="w-full mb-5">
                  <h3 className="leading-none">
                    {t(`dashboard.errorConnectingYourAccount`)} {accountEmail}
                  </h3>

                  <p className="mt-2 text-red-500">{error}</p>
                </div>
                <div className="flex justify-between mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={() => setSupportOpen(false)}
                    className="flex items-center gap-1.5 rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex mr-4"
                  >
                    {t(`dashboard.figureItOutMyself`)}
                  </button>
                  <button
                    type="submit"
                    className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm ml-4"
                  >
                    {t(`dashboard.askSupport`)}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};

export default AuthSupportModal;

import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';

import Skeleton from 'global-components/Skeleton';
import { useDataContext } from 'context/UserContext';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

function Graph() {
  const { user } = useDataContext();

  if (!user.language) {
    const languagedetector = new LanguageDetector();

    let language = languagedetector.detect();

    if (language !== 'es' && !language.includes('en-US')) {
      language = 'en';
    }

    user.language = language;
  }

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  const [lineData, setLineData] = useState();

  const [activeFilter, setActiveFilter] = useState('currentWeek');

  const [{ data: checkoutList = [], loading, error }] = useAxios(
    '/analytics/checkouts'
  );

  useEffect(() => {
    if (loading || error || !checkoutList) return;
    setMonthly();
  }, [checkoutList, error, loading]);

  const checkoutTotal = checkoutList?.reduce((acc, cur) => {
    return acc + cur.price;
  }, 0);

  const setCurrentWeek = () => {
    let checkouts = checkoutList;

    setActiveFilter('currentWeek');

    checkouts = checkouts.filter(item => {
      return dayjs(new Date(item.time)).isAfter(dayjs().subtract(1, 'week'));
    });

    checkouts.sort((a, b) => a.time - b.time);

    const groupedCheckouts = checkouts.reduce((acc, item) => {
      const day = new Date(item.time).toLocaleString('en-us', {
        weekday: 'short'
      });
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(item);
      return acc;
    }, {});

    checkouts = Object.values(groupedCheckouts);

    const days = Object.keys(groupedCheckouts);

    const lineChartData = {
      data: {
        labels: days,
        datasets: [
          {
            fill: 'start',
            label: t('dashboard.chartData.moneySaved'),
            data: checkouts.map(item => {
              let total = 0;
              item.forEach(i => {
                total += i.price;
              });
              return total;
            }),
            borderColor: '#FA4088',
            backgroundColor: 'rgba(250,64,136,0.2)',
            pointRadius: 0,
            tension: 0.1
          }
        ]
      }
    };

    setLineData(lineChartData);
  };

  const setMonthly = () => {
    let checkouts = checkoutList;

    setActiveFilter('currentMonth');

    const currentMonthCheckouts = checkouts.filter(item => {
      return dayjs(new Date(item.time)).isSame(dayjs(), 'month');
    });

    const lastMonthCheckouts = checkouts.filter(item => {
      return dayjs(new Date(item.time)).isSame(
        dayjs().subtract(1, 'month'),
        'month'
      );
    });

    if (currentMonthCheckouts.length > lastMonthCheckouts.length) {
      checkouts = checkouts.filter(item => {
        return (
          dayjs(new Date(item.time)).isSame(dayjs(), 'month') ||
          dayjs(new Date(item.time)).isAfter(dayjs().subtract(1, 'year'))
        );
      });
    } else {
      checkouts = checkouts.filter(item => {
        return dayjs(new Date(item.time)).isAfter(dayjs().subtract(1, 'year'));
      });
    }
    checkouts.sort((a, b) => a.time - b.time);

    const groupedCheckouts = checkouts.reduce((acc, item) => {
      const month = new Date(item.time).toLocaleString('en-us', {
        month: 'short'
      });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});

    checkouts = Object.values(groupedCheckouts);

    const months = Object.keys(groupedCheckouts);

    const lineChartData = {
      data: {
        labels: months.reverse(),
        datasets: [
          {
            fill: 'start',
            label: t('dashboard.chartData.moneySaved'),
            data: checkouts
              .map(item => {
                let total = 0;
                item.forEach(i => {
                  total += i.price;
                });
                return total;
              })
              .reverse(),
            borderColor: '#FA4088',
            backgroundColor: 'rgba(250,64,136,0.2)',
            pointRadius: 0,
            tension: 0.1
          }
        ]
      }
    };

    setLineData(lineChartData);
  };

  if (loading) {
    return (
      <div className="mt-2.5">
        <Skeleton classes="h-96 w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-5 lg:pt-0">
        <div className="flex items-center justify-center p-5 py-40 text-center text-gray-400 bg-gray-100 rounded-lg">
          Error loading your checkout analytics. Please try again later.
        </div>
      </div>
    );
  }

  if (checkoutTotal > 0) {
    return (
      <div className="mt-2.5 w-full rounded-lg border bg-white p-5 shadow-sm">
        <div className="flex flex-col justify-between md:flex-row md:items-center">
          <div className="font-semibold">
            🚀 {t('dashboard.chartData.overTime')}
          </div>
          <div className="mt-2.5 space-x-1.5 md:ml-2 lg:mt-0">
            <button
              className={`rounded-full border px-3.5 py-1.5 text-xs font-medium shadow-sm ${
                activeFilter === 'currentWeek'
                  ? 'border-gray-800 bg-gray-800 text-white !shadow-none'
                  : 'bg-white'
              }`}
              onClick={() => {
                setCurrentWeek();
              }}
              type="button"
            >
              {t('dashboard.chartData.pastWeek')}
            </button>

            <button
              className={`rounded-full border px-3.5 py-1.5 text-xs font-medium shadow-sm ${
                activeFilter === 'currentMonth'
                  ? 'border-gray-800 bg-gray-800 text-white !shadow-none'
                  : 'bg-white'
              }`}
              onClick={() => {
                setMonthly();
              }}
              type="button"
            >
              {t('dashboard.chartData.monthly')}
            </button>
          </div>
        </div>
        {lineData?.data && (
          <Line
            className="mt-2.5"
            data={lineData?.data}
            options={lineData.options}
          />
        )}
      </div>
    );
  }

  return (
    <div className="mt-2.5">
      <div className="flex items-center justify-center p-5 py-40 text-center text-gray-400 bg-white border rounded-md shadow-sm">
        You will see your checkout analytics here once you get your first
        checkout!
      </div>
    </div>
  );
}

export default Graph;
